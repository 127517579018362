import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';

import { ButtonFill, ButtonSize, SortTypes } from 'types/enums';

import { ReactComponent as ArrowDown } from 'assets/images/arrow-down.svg';

import styles from './NewSortButton.module.scss';

type Props = {
  sortType: string;
  onSort: () => void;
};

const NewSortButton = ({ sortType, onSort }: Props) => {
  const { t } = useTranslation();

  return (
    <ButtonStyled
      onClick={onSort}
      fill={ButtonFill.TransparentBold}
      size={ButtonSize.Bold}
      icon={
        <ArrowDown
          className={classNames(styles.Arrow, {
            [styles.Sort]: sortType === SortTypes.DESC,
          })}
        />
      }
    >
      {`${t('general.sort_by')} ${sortType === SortTypes.DESC ? 'Z-A' : 'A-Z'}`}
    </ButtonStyled>
  );
};

export default NewSortButton;
