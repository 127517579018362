import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import { Radio, RadioGroup } from 'rsuite';

import NewSearchBar from 'components/NewSearchBar/NewSearchBar';
import NothingFoundText from 'components/NothingFoundText/NothingFoundText';
import Scrollable from 'components/Scrollable/Scrollable';

import {
  useAssignmentAssigneesOverwriteMutation,
  useGetDiagnosticAssignmentsQuery,
} from 'store/api/assessmentApi/assessmentApi';

import { createDiagnosticAssignmentUserModal } from 'helpers/validations';

import { TagType } from 'types/assessmentTypes';
import { ModalSize } from 'types/modalTypes';

import Loader from '../../Loader/Loader';
import Tags from '../../Tags/Tags';
import ModalWrapper from '../ModalWrapper';

import styles from './CreateDiagnosticAssignmentUserModal.module.scss';

type Props = {
  open: boolean;
  handleClose: () => void;
  userId?: string;
};

type FormValues = {
  selectedDiagnosticAssignmentId: string;
};

const CreateDiagnosticAssignmentUserModal = ({ open, handleClose, userId, ...props }: Props) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');

  const { data: { items: diagnosticAssignments = [] } = {}, isFetching: isLoadingDiagnosticAssignments } =
    useGetDiagnosticAssignmentsQuery({ size: 100 }, { skip: !open });
  const [assignmentAssigneesOverwrite, { isLoading: isLoadingAssignmentAssigneesOverwrite }] =
    useAssignmentAssigneesOverwriteMutation();

  const { handleSubmit, formState, watch, reset, control } = useForm<FormValues>({
    resolver: yupResolver(createDiagnosticAssignmentUserModal),
    mode: 'all',
    defaultValues: {
      selectedDiagnosticAssignmentId: '',
    },
  });

  const filteredDiagnosticAssignments = diagnosticAssignments?.filter((item) =>
    item.version_name.trim().toLowerCase().includes(search.trim().toLowerCase()),
  );

  const selectedDiagnosticId = watch('selectedDiagnosticAssignmentId');

  const selectedDiagnosticAssignment = diagnosticAssignments?.find((item) => item.id === selectedDiagnosticId);
  const tags = selectedDiagnosticAssignment
    ? [
        {
          id: selectedDiagnosticAssignment?.id,
          title: selectedDiagnosticAssignment?.version_name,
          type: TagType.Organization,
        },
      ]
    : [];

  const addAssignees = ({ selectedDiagnosticAssignmentId }: FormValues) => {
    if (userId && selectedDiagnosticAssignment) {
      assignmentAssigneesOverwrite({
        diagnosticId: selectedDiagnosticAssignment.assessment_id,
        diagnosticAssignmentId: selectedDiagnosticAssignmentId,
        added: [userId],
        removed: [],
      })
        .unwrap()
        .then(() => {
          toast.success(t('assessment.invitation_sent'));
          onClose();
        })
        .catch(() => {
          toast.error(t('general.error'));
        });
    }
  };

  const onClose = () => {
    setSearch('');
    reset();
    handleClose();
  };

  return (
    <ModalWrapper
      {...props}
      title={t('assessment.send_assignment')}
      open={open}
      close={onClose}
      actionTitle={t('assessment.send_invite')}
      cancelTitle={t('general.cancel')}
      actionDisabled={!formState.isValid}
      action={handleSubmit(addAssignees)}
      modalSpacing={ModalSize.NoSpacing}
      status={isLoadingAssignmentAssigneesOverwrite}
    >
      <div className={styles.Description}>{t('assessment.assignment_user_modal_description')}</div>

      <span className={styles.DiagnosticName}>{t('assessment.assignment_name')}</span>

      {tags.length ? (
        <div className={styles.TagsContainer}>
          <Tags tags={tags} handleDelete={() => reset({ selectedDiagnosticAssignmentId: '' })} />
        </div>
      ) : null}

      <div className={styles.SearchBar}>
        <NewSearchBar search={search} onSearch={setSearch} />
      </div>

      {isLoadingDiagnosticAssignments ? (
        <div className={styles.LoaderContainer}>
          <Loader flexCenter size="md" />
        </div>
      ) : (
        <Scrollable>
          <Controller
            control={control}
            name="selectedDiagnosticAssignmentId"
            render={({ field: { onChange, value } }) => (
              <RadioGroup value={value} onChange={onChange}>
                {filteredDiagnosticAssignments.length > 0 ? (
                  filteredDiagnosticAssignments?.map((item) => (
                    <Radio key={item.id} value={item.id} className={styles.Radio}>
                      {item.version_name}
                    </Radio>
                  ))
                ) : (
                  <NothingFoundText style={{ minHeight: '40px' }} text={t('assessment.nothing_found')} />
                )}
              </RadioGroup>
            )}
          />
        </Scrollable>
      )}
    </ModalWrapper>
  );
};

export default CreateDiagnosticAssignmentUserModal;
