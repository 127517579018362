import { useTranslation } from 'react-i18next';

import { OutlinedInput } from '@mui/material';
import { InputAdornment } from '@mui/material';

import { ReactComponent as SearchMdIcon } from 'assets/images/search-md.svg';

import styles from './NewSearch.module.scss';

type Props = {
  value?: string;
  onSearch: (arg0: string) => void;
  placeholder?: string;
  small?: boolean;
  disabled?: boolean;
};

const NewSearch = ({ placeholder, value, onSearch, small, disabled }: Props) => {
  const { t } = useTranslation();

  return (
    <OutlinedInput
      disabled={disabled}
      className={styles.Container}
      placeholder={placeholder || t('general.search_placeholder')}
      value={value}
      inputProps={{ maxLength: 160 }}
      onChange={(e) => onSearch(e.target.value)}
      sx={{
        paddingLeft: '12px',
        border: '1px solid var(--cool-grey)',
        '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
        '& input': {
          padding: small ? '8px' : '10px', // Adds padding around the placeholder and input text
          paddingLeft: '8px',
        },
        '& input::placeholder': { color: 'var(--cool-grey)', opacity: 1 }, // Custom placeholder color
        '& .MuiInputAdornment-root': {
          marginRight: '0px',
        },
      }}
      startAdornment={
        <InputAdornment position="start">
          <SearchMdIcon className={styles.SearchIcon} />
        </InputAdornment>
      }
    />
  );
};

export default NewSearch;
