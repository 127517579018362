import { toast } from 'react-toastify';

import { TagDescription, createApi } from '@reduxjs/toolkit/dist/query/react';
import { t } from 'i18next';

import { formatDate } from 'helpers/date';

import { baseAssessmentQueryWithReauth } from '../../helpers';

import {
  DownloadGroupPdfPayload,
  DownloadGroupPdfResponse,
  DownloadUserPdfPayload,
  DownloadUserPdfResponse,
  GetContextsPayload,
  GetContextsResponse,
  GetMappingsGroupAccelerantsPayload,
  GetMappingsGroupAccelerantsResponse,
  GetMappingsGroupEcosystemPayload,
  GetMappingsGroupEcosystemResponse,
  GetMappingsGroupPayload,
  GetMappingsGroupResponse,
  GetMappingsPayload,
  GetMappingsResponse,
} from './types';

type AvailableTag = TagDescription<'SCurveMapping' | 'AccelerantMapping' | 'EcosystemMapping' | 'Context'>;

export const tagsMap = {
  s_curve: ['SCurveMapping' as AvailableTag],
  accelerants: ['AccelerantMapping' as AvailableTag],
  ecosystem: ['EcosystemMapping' as AvailableTag],
};

export const mappingApi = createApi({
  tagTypes: ['SCurveMapping', 'AccelerantMapping', 'EcosystemMapping', 'Context'],
  reducerPath: 'mappingApi',
  baseQuery: baseAssessmentQueryWithReauth,
  endpoints: (builder) => ({
    getMappings: builder.query<GetMappingsResponse, GetMappingsPayload>({
      query: ({ completionId, visualisationType }) => ({
        url: `/mappings/${completionId}/`,
        params: {
          visualisation_type: visualisationType,
        },
      }),
      providesTags: (result, error, arg: GetMappingsPayload) => {
        return tagsMap[arg.visualisationType];
      },
    }),

    getMappingsGroupScurve: builder.query<GetMappingsGroupResponse, GetMappingsGroupPayload>({
      query: ({ groupId, assessmentId, dateFrom, dateTo }) => ({
        url: `/mappings/group/${groupId}/s-curve/`,
        params: assessmentId
          ? {
              assessment_id: assessmentId,
              date_from: dateFrom,
              date_to: dateTo,
            }
          : {},
      }),

      providesTags: (result, error, arg: GetMappingsGroupPayload) => {
        return tagsMap[arg.visualisationType];
      },
    }),

    getMappingsGroupAccelerants: builder.query<GetMappingsGroupAccelerantsResponse, GetMappingsGroupAccelerantsPayload>(
      {
        query: ({ groupId, assessmentId, dateFrom, dateTo }) => ({
          url: `/mappings/group/${groupId}/accelerants/`,
          params: assessmentId
            ? {
                assessment_id: assessmentId,
                date_from: dateFrom,
                date_to: dateTo,
              }
            : {},
        }),

        providesTags: (result, error, arg: GetMappingsGroupAccelerantsPayload) => {
          return tagsMap[arg.visualisationType];
        },
      },
    ),

    getMappingsGroupEcosystem: builder.query<GetMappingsGroupEcosystemResponse, GetMappingsGroupEcosystemPayload>({
      query: ({ groupId, assessmentId, dateFrom, dateTo }) => ({
        url: `/mappings/group/${groupId}/ecosystem/`,
        params: assessmentId
          ? {
              assessment_id: assessmentId,
              date_from: dateFrom,
              date_to: dateTo,
            }
          : {},
      }),

      providesTags: (result, error, arg: GetMappingsGroupEcosystemPayload) => {
        return tagsMap[arg.visualisationType];
      },
    }),

    getContexts: builder.query<GetContextsResponse, GetContextsPayload>({
      query: ({ completionId }) => `/context/${completionId}/`,
      providesTags: ['Context'],
    }),

    downloadGroupPdf: builder.mutation<DownloadGroupPdfResponse, DownloadGroupPdfPayload>({
      queryFn: async (groupId, api, extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: `/mappings/group/${groupId}/pdf/`,
          method: 'GET',
          responseHandler: (response) => {
            return response.blob();
          },
        });

        if (result.data) {
          const hiddenElement = document.createElement('a');
          const url = window.URL || window.webkitURL;
          const blobPDF = url.createObjectURL(result.data as Blob);
          hiddenElement.href = blobPDF;
          hiddenElement.target = '_blank';
          hiddenElement.download = `report-for-group-${groupId}-${formatDate(new Date().toString())}.pdf`;
          hiddenElement.click();
        } else {
          toast.error(t('dashboard.failed_to_download_report'));
        }

        return { data: null };
      },
    }),

    downloadUserPdf: builder.mutation<DownloadUserPdfResponse, DownloadUserPdfPayload>({
      queryFn: async (payload, api, extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: `/mappings/user/pdf/`,
          method: 'POST',
          body: payload,
          responseHandler: (response) => {
            return response.blob();
          },
        });

        if (result.data) {
          const hiddenElement = document.createElement('a');
          const url = window.URL || window.webkitURL;
          const blobPDF = url.createObjectURL(result.data as Blob);
          hiddenElement.href = blobPDF;
          hiddenElement.target = '_blank';
          hiddenElement.download = `report-for-${payload.first_name} ${payload.last_name}-${formatDate(
            new Date().toString(),
          )}.pdf`;
          hiddenElement.click();
        } else {
          toast.error(t('dashboard.failed_to_download_report'));
        }

        return { data: null };
      },
    }),
  }),
});

export const {
  useLazyGetMappingsQuery,
  useLazyGetMappingsGroupScurveQuery,
  useLazyGetMappingsGroupAccelerantsQuery,
  useLazyGetMappingsGroupEcosystemQuery,
  useLazyGetContextsQuery,
  useGetContextsQuery,
  useDownloadGroupPdfMutation,
  useDownloadUserPdfMutation,
} = mappingApi;
