import React from 'react';
import { useTranslation } from 'react-i18next';

import ModalWrapper from '../ModalWrapper';

type Props = {
  open: boolean;
  close: () => void;
  handleDelete: () => void;
  actionDisabled: boolean;
  status: boolean;
};

const DeleteDiagnosticAssignmentModal = ({ open, close, handleDelete, actionDisabled, status }: Props) => {
  const { t } = useTranslation();

  return (
    <ModalWrapper
      open={open}
      title={t('delete_diagnostic_assignment.delete_diagnostic_assignment_title')}
      subtitle={t('delete_diagnostic_assignment.delete_diagnostic_assignment_subtitle')}
      close={close}
      action={handleDelete}
      actionDisabled={actionDisabled}
      status={status}
    />
  );
};

export default DeleteDiagnosticAssignmentModal;
