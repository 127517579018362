import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import classNames from 'classnames';
import { Modal } from 'rsuite';

import { MOBILE_BREAKPOINT } from 'constants/';

import { ButtonFill, ButtonSize } from 'types/enums';
import { ModalProps, ModalSize } from 'types/modalTypes';

import { ReactComponent as Delete } from 'assets/images/delete-icon.svg';
import AttentionSVG from 'assets/svg/AttentionSVG';
import CrossSvgIcon from 'assets/svg/CrossSvgIcon';

import ButtonStyled from '../Buttons/ButtonStyled/ButtonStyled';

import styles from './ModalWrapper.module.scss';

const ModalWrapper = ({
  open,
  overflow,
  title,
  subtitle,
  children,
  header = null,
  footer = null,
  status,
  actionTitle,
  cancelTitle,
  cleanAfterExited,
  close,
  action,
  isForm,
  clean,
  modalSpacing,
  modalWrapperClassName,
  actionDisabled,
  attention,
  footerDeleteAction,
  footerDeleteActionTitle,
  large,
}: ModalProps) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: MOBILE_BREAKPOINT,
  });

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = 'unset';
      };
    }
  }, [open]);

  const handleClose = () => {
    close?.();
    clean?.();
  };

  return (
    <Modal
      overflow={overflow}
      backdrop="static"
      open={open}
      onClose={handleClose}
      onExited={cleanAfterExited}
      className={classNames(styles.Modal, modalWrapperClassName, {
        [styles.Overflow]: overflow,
        [styles.Large]: large,
      })}
      dialogClassName={styles.ModalDialog}
    >
      <button className={styles.CloseIcon} onClick={handleClose}>
        <CrossSvgIcon />
      </button>

      {header ?? (
        <header
          className={classNames(styles.ModalHeader, { [styles.NoSpacing]: modalSpacing === ModalSize.NoSpacing })}
        >
          {attention && (
            <div className={styles.AttentionModalHeader}>
              <AttentionSVG />
              <h3>{t('general.attention')}!</h3>
            </div>
          )}
          <h3 className={styles.ModalTitle}>{title}</h3>
          <h4 className={styles.ModalSubTitle}>{subtitle}</h4>
        </header>
      )}

      {children && (
        <Modal.Body
          className={classNames(styles.ModalBody, {
            [styles.IsForm]: isForm,
            [styles.NoSpacing]: modalSpacing === ModalSize.NoSpacing,
          })}
        >
          {children}
        </Modal.Body>
      )}

      {!isForm && (
        <footer className={styles.ModalFooter}>
          {footer ?? (
            <div className={styles.ModalGeneralControls}>
              {close && (
                <ButtonStyled fill={ButtonFill.Outlined} onClick={close} fullWidth>
                  {cancelTitle ?? t('general.cancel')}
                </ButtonStyled>
              )}
              {action && (
                <ButtonStyled
                  fill={attention ? ButtonFill.Attention : ButtonFill.Contained}
                  onClick={action}
                  loading={status}
                  disabled={actionDisabled}
                  fullWidth
                >
                  {actionTitle ?? t('general.proceed')}
                </ButtonStyled>
              )}
            </div>
          )}
          {footerDeleteAction && (
            <div className={styles.FooterDeleteActionButton}>
              <ButtonStyled
                key="delete"
                fill={ButtonFill.TransparentRed}
                size={ButtonSize.Small}
                onClick={footerDeleteAction}
                icon={<Delete />}
                fullWidth={isMobile}
              >
                {footerDeleteActionTitle || t('general.delete')}
              </ButtonStyled>
            </div>
          )}
        </footer>
      )}
    </Modal>
  );
};

export default ModalWrapper;
