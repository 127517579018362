import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import Select from 'components/Inputs/Select/Select';

import { useAppSelector } from 'hooks/redux';

import { useGetGroupHierarchyQuery } from 'store/api/groupsApi/groupsApi';

import { mapToSelectGroupHierarchy } from 'helpers/mappers/mapToSelectGroupHierarchy';

import { DiagnosticVersionStatus } from 'types/enums';
import { ButtonFill, ButtonSize } from 'types/enums';

import { ReactComponent as Delete } from 'assets/images/delete-icon.svg';

import styles from './FilterByBlock.module.scss';

type FormValues = {
  status: DiagnosticVersionStatus | '';
  groupId: string;
};

const defaultValues: FormValues = { status: '', groupId: '' };

type Props = {
  onFilter: (data: FormValues) => void;
  onClear?: () => void;
  value?: FormValues;
};

const FilterByBlock = ({ onFilter, onClear, value }: Props) => {
  const { t } = useTranslation();
  const selectedGroup = useAppSelector((state) => state.navigation.selectedGroupId);

  const { handleSubmit, formState, getFieldState, reset, control } = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      ...defaultValues,
      ...value,
    },
  });

  const { isLoading: isLoadingGroups, data: groupsData } = useGetGroupHierarchyQuery({
    search: '',
    orderBy: 'name',
    selectedGroup,
  });

  const statuses = [
    { value: DiagnosticVersionStatus.Scheduled, label: t('general.scheduled') },
    { value: DiagnosticVersionStatus.Sent, label: t('general.sent') },
    { value: DiagnosticVersionStatus.Opened, label: t('general.opened') },
    { value: DiagnosticVersionStatus.Completed, label: t('general.completed') },
    { value: DiagnosticVersionStatus.ScheduledSendingError, label: t('general.sending_error') },
  ];

  const mappedGroups = groupsData?.items.map((el) => mapToSelectGroupHierarchy(el)) || [];

  return (
    <div className={styles.Container}>
      <span className={styles.Title}>{t('general.filter_by')}</span>

      <Controller
        control={control}
        name="status"
        render={({ field: { onChange, value } }) => (
          <Select
            value={value}
            onSelect={onChange}
            data={statuses}
            isLoading={false}
            label={t('inputs.status')}
            placeholder={t('inputs.status_placeholder')}
            error={getFieldState('status', formState)}
            error_message={t(formState?.errors.status?.message || '')}
          />
        )}
      />

      <Controller
        control={control}
        name="groupId"
        render={({ field: { onChange, value } }) => (
          <Select
            cascade
            value={value}
            onSelect={onChange}
            data={mappedGroups}
            isLoading={isLoadingGroups}
            label={t('inputs.group')}
            placeholder={t('inputs.group_placeholder')}
            error={getFieldState('groupId', formState)}
            error_message={t(formState?.errors.groupId?.message || '')}
          />
        )}
      />

      <div className={styles.Controls}>
        <ButtonStyled
          key="delete"
          fill={ButtonFill.TransparentRed}
          size={ButtonSize.Small}
          onClick={() => {
            reset(defaultValues);
            onClear?.();
          }}
          icon={<Delete />}
        >
          {t('general.clear_all')}
        </ButtonStyled>

        <ButtonStyled
          fill={false ? ButtonFill.Attention : ButtonFill.Contained}
          size={ButtonSize.Small}
          onClick={handleSubmit(onFilter)}
        >
          {t('general.apply')}
        </ButtonStyled>
      </div>
    </div>
  );
};

export default FilterByBlock;
