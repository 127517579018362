import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Outlet, useNavigate } from 'react-router-dom';

import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import CreateDiagnosticAssignmentModal from 'components/Modals/CreateDiagnosticAssignmentModal/CreateDiagnosticAssignmentModal';
import NewUnitModal from 'components/Modals/NewUnitModal/NewUnitModal';
import PageTitle from 'components/PageTitle/PageTitle';

import { useAppSelector } from 'hooks/redux';
import { useDebounce } from 'hooks/useDebounce';
import useTableControl from 'hooks/useTableControl';

import ROUTES from 'router/routes';

import { checkPermission } from 'helpers/checkUserPermission';

import { MOBILE_BREAKPOINT } from 'constants/';

import { AssessmentListTab } from 'types/assessmentTypes';
import { ButtonFill, ButtonSize } from 'types/enums';
import { PermissionsAction } from 'types/permissionsTypes';

import styles from './AssessmentList.module.scss';

const AssessmentList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { unit, assessment } = useAppSelector((state) => state.permissions.permissions);

  const { page, size, sort, search, onPageChange, onSearching, onSorting } = useTableControl('title');
  const debouncedSearch = useDebounce(search, 500);
  const isMobile = useMediaQuery({
    query: MOBILE_BREAKPOINT,
  });

  const [activeTab, changeTab] = useState(AssessmentListTab.LIST);
  const [openNewUnitModal, toggleNewUnitModal] = useState(false);
  const [openAssignDiagnosticVersionModal, toggleAssignDiagnosticVersionModal] = useState(false);
  const tabs = [
    { title: t('assessment.diagnostic'), route: ROUTES.DIAGNOSTIC_LIST },
    { title: t('assessment.diagnostic_results'), route: ROUTES.DIAGNOSTIC_RESULTS },
  ];

  const tableProps = {
    page,
    size,
    sort,
    search: debouncedSearch,
    onPageChange,
  };

  const handleCloseNewUnitModal = () => toggleNewUnitModal(false);

  const getPageTitleAction = () => {
    const actions = [];

    if (checkPermission(unit, [PermissionsAction.CREATE])) {
      actions.push(
        <ButtonStyled
          key="new_unit"
          size={ButtonSize.Small}
          fill={ButtonFill.Transparent}
          onClick={(e) => {
            e.stopPropagation();
            toggleNewUnitModal(true);
          }}
        >
          {t('assessment.new_unit')}
        </ButtonStyled>,
      );
    }

    if (!isMobile && checkPermission(assessment, [PermissionsAction.CREATE])) {
      actions.push(
        <ButtonStyled
          key="new_diagnostic"
          size={ButtonSize.Small}
          fill={ButtonFill.Outlined}
          onClick={() => navigate(ROUTES.DIAGNOSTIC_CREATE)}
        >
          {t('assessment.new_diagnostic')}
        </ButtonStyled>,
      );
    }

    if (!isMobile && checkPermission(assessment, [PermissionsAction.ASSIGN])) {
      actions.push(
        <ButtonStyled
          key="new_assignment"
          size={ButtonSize.Small}
          fill={ButtonFill.Contained}
          onClick={() => toggleAssignDiagnosticVersionModal(true)}
        >
          {t('assessment.new_assignment')}
        </ButtonStyled>,
      );
    }

    return actions;
  };

  const pageTitleActions = getPageTitleAction();

  return (
    <div className={styles.AssessmentListPage}>
      <NewUnitModal open={openNewUnitModal} handleClose={handleCloseNewUnitModal} />
      <CreateDiagnosticAssignmentModal
        open={openAssignDiagnosticVersionModal}
        handleClose={() => toggleAssignDiagnosticVersionModal(false)}
      />

      <PageTitle
        newSearch
        controls
        tabsProps={{
          tabs,
          activeTab,
          changeTab,
          spaceBetweenChildren: true,
          fontLarge: true,
        }}
        searchPlaceholder={t('assessment.search_placeholder')}
        search={search}
        sort={sort.sortType}
        onSort={onSorting}
        onSearch={onSearching}
      >
        {pageTitleActions.length ? pageTitleActions : null}
      </PageTitle>

      <Outlet context={{ tableProps, activeTab, onPageChange, changeTab }} />
    </div>
  );
};

export default AssessmentList;
