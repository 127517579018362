import classNames from 'classnames';

import styles from './NothingFoundText.module.scss';

type Props = {
  text: string;
  center?: boolean;
  style?: React.CSSProperties;
};

const NothingFoundText = ({ text, center, style }: Props) => (
  <div style={style} className={classNames(styles.NothingFoundContainer, center && styles.Center)}>
    <span>{text}</span>
  </div>
);

export default NothingFoundText;
