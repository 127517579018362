export enum SortTypes {
  ASC = 'asc',
  DESC = 'desc',
}

export enum ResultsMappingStep {
  Scurve = 'scurve',
  Accelerants = 'accelerants',
  Ecosystem = 'ecosystem',
}

export enum ResultsMappingDisplayMode {
  View = 'view',
  Edit = 'edit',
}

export enum Size {
  Sm = 'Sm',
  Md = 'Md',
  Lg = 'Lg',
}

export enum CardColor {
  Yellow = 'Yellow',
  Blue = 'Blue',
  White = 'White',
}

export enum DeleteType {
  Soft = 'soft',
  Hard = 'hard',
}

export enum BulkAction {
  Delete = 'delete',
  Role = 'role',
  Deactivate = 'deactivate',
  Copy = 'copy',
}

export enum SortingMode {
  Client = 'client',
  Server = 'server',
}

export enum ButtonFill {
  Contained = 'Contained',
  Outlined = 'Outlined',
  Transparent = 'Transparent',
  TransparentRed = 'TransparentRed',
  TransparentBold = 'TransparentBold',
  Attention = 'Attention',
}

export enum ButtonSize {
  Normal = 'Normal',
  Bold = 'Bold',
  Small = 'Small',
  Tag = 'Tag',
  Long = 'Long',
  Link = 'Link',
  Icon = 'Icon',
}

export enum DiagnosticVersionStatus {
  Sent = 'sent',
  Opened = 'opened',
  Completed = 'completed',
  Scheduled = 'scheduled',
  ScheduledSendingError = 'scheduled_sending_error',
}

export enum UserStatus {
  Active = 'active',
  Disabled = 'disabled',
}
