import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';

import { ButtonFill, ButtonSize } from 'types/enums';

import { ReactComponent as FilterLines } from 'assets/images/filter-lines.svg';

import styles from './FilterByButton.module.scss';

type Props = {
  sortType: string;
  show: boolean;
  onSort: () => void;
};

const FilterByButton = ({ sortType, show, onSort }: Props) => {
  const { t } = useTranslation();

  return (
    <ButtonStyled
      additionalClassNames={classNames(styles.SortButton, show && styles.Active)}
      onClick={onSort}
      fill={ButtonFill.TransparentBold}
      size={ButtonSize.Bold}
      icon={<FilterLines />}
    >
      {`${t('general.filter_by')}`}
    </ButtonStyled>
  );
};

export default FilterByButton;
