import { JSXElementConstructor, useCallback, useMemo, useState } from 'react';

import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridInitialState,
  GridInputRowSelectionModel,
  GridRow,
  GridRowIdGetter,
  GridRowParams,
  GridRowSelectionModel,
  GridRowSpacingParams,
  GridSortModel,
} from '@mui/x-data-grid';
import classNames from 'classnames';

import CustomCheckbox from './components/CustomCheckbox/CustomCheckbox';
import CustomNoRowsOverlay from './components/CustomNoRowsOverlay/CustomNoRowsOverlay';
import { CustomPagination } from './components/CustomPagination/CustomPagination';

import { getFieldId } from 'helpers/table';

import { SortingMode } from 'types/enums';

import { AscendingSortIcon, DescendingSortIcon, SortIcon } from 'assets/svg/data-grid';

import './DataTable.scss';

type Props = {
  columns: GridColDef<any>[];
  data:
    | {
        items: any[];
        total?: number;
        errorMessages?: string[];
      }
    | undefined;
  isLoading: boolean;
  page: number;
  customPageSize?: number;
  enablePageSize?: boolean;
  enableSelectionOnClick?: boolean;
  checkboxSelection?: boolean;
  changePage: (value: number) => void;
  onSelectData?: () => void;
  emptyResultText?: string;
  onRowClick?: (arg0: GridRowParams) => void;
  rowHoverPointer?: boolean;
  onSelectionModelChange?:
    | ((selectionModel: GridRowSelectionModel, details: GridCallbackDetails<any>) => void)
    | undefined;
  onSortModelChange?: (sortModel?: GridSortModel) => void;
  selectionModel?: GridInputRowSelectionModel;
  getRowId?: GridRowIdGetter<any> | undefined;
  sortingMode?: SortingMode;
  components?: {
    ColumnUnsortedIcon: JSXElementConstructor<any>;
    ColumnSortedAscendingIcon: JSXElementConstructor<any>;
    ColumnSortedDescendingIcon: JSXElementConstructor<any>;
  };
  newDesign?: boolean;
  initialState?: GridInitialState;
};

const DataTable = ({
  newDesign,
  columns,
  data,
  isLoading,
  customPageSize,
  page,
  enablePageSize,
  enableSelectionOnClick,
  checkboxSelection,
  changePage,
  emptyResultText,
  onRowClick,
  rowHoverPointer,
  onSelectionModelChange,
  onSortModelChange,
  selectionModel,
  sortingMode = SortingMode.Client,
  components,
  ...props
}: Props) => {
  const {
    ColumnUnsortedIcon = SortIcon,
    ColumnSortedAscendingIcon = AscendingSortIcon,
    ColumnSortedDescendingIcon = DescendingSortIcon,
  } = components || {};

  const getRowSpacing = useCallback((params: GridRowSpacingParams) => {
    if (params.model?.errorMessages?.length) {
      return {
        top: 24,
        bottom: 0,
      };
    }

    return {
      top: params.isFirstVisible ? 0 : 4,
      bottom: 0,
    };
  }, []);
  const DEFAULT_PAGE_SIZE = 12;

  const [paginationModel, setPaginationModel] = useState({
    pageSize: customPageSize || DEFAULT_PAGE_SIZE,
    page: 0,
  });

  const isManyPages =
    data && data?.total && (data?.total > paginationModel.pageSize || data?.items.length > paginationModel.pageSize);

  const renderPagination = enablePageSize || (!enablePageSize && isManyPages);

  const rows = useMemo(
    () =>
      data
        ? data?.items.map((item, index, array) => ({
            ...item,
            fieldId: getFieldId({ size: paginationModel.pageSize, page, index, total: array.length }),
          }))
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  );

  const dataGridSx = {
    '& .MuiDataGrid-virtualScroller': {
      overflowX: 'hidden !important',
    },

    '.MuiDataGrid-iconButtonContainer': {
      visibility: 'visible',
    },

    '.MuiDataGrid-row': {
      cursor: rowHoverPointer ? 'pointer' : 'auto',
    },
  };

  return (
    <div className="custom-table-wrapper">
      <DataGrid
        hideFooterSelectedRowCount
        autoHeight
        columnHeaderHeight={32}
        pagination
        disableColumnMenu
        sx={dataGridSx}
        onRowClick={
          onRowClick
            ? (params) => {
                onRowClick(params);
              }
            : undefined
        }
        hideFooter={!isManyPages}
        rowHeight={48}
        rowSelectionModel={selectionModel}
        onRowSelectionModelChange={onSelectionModelChange}
        onSortModelChange={onSortModelChange}
        checkboxSelection={checkboxSelection}
        className={classNames('custom-table', newDesign && 'new-design')}
        sortingMode={sortingMode}
        rows={rows}
        columns={columns}
        loading={isLoading}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
        disableRowSelectionOnClick={!enableSelectionOnClick}
        getRowSpacing={getRowSpacing}
        slots={{
          noRowsOverlay: () => <CustomNoRowsOverlay text={emptyResultText} />,
          pagination: () =>
            renderPagination ? (
              <CustomPagination
                enablePageSize={enablePageSize || false}
                changePageSize={setPaginationModel}
                pageSize={paginationModel.pageSize}
                changePage={changePage}
                page={page}
                count={data?.total || data?.items.length || 0}
              />
            ) : null,
          baseCheckbox: CustomCheckbox,
          columnUnsortedIcon: ColumnUnsortedIcon,
          columnSortedAscendingIcon: ColumnSortedAscendingIcon,
          columnSortedDescendingIcon: ColumnSortedDescendingIcon,
          row: (props) => {
            if (props.row?.errorMessages?.length) {
              return (
                <div className="MuiDataGrid-row--invalid-wrapper">
                  <p>{props.row.errorMessages.join(', ')}</p>
                  <GridRow className="MuiDataGrid-row--invalid" {...props} />
                </div>
              );
            }

            return <GridRow {...props} />;
          },
        }}
        {...props}
      />
    </div>
  );
};

export default DataTable;
