import { toast } from 'react-toastify';

import { createApi } from '@reduxjs/toolkit/query/react';
import { t } from 'i18next';

import {
  ActivateUsersPayload,
  ActivateUsersResponse,
  ChangePasswordPayload,
  ChangePasswordResponse,
  ReceiveActivationEmailPayload,
  ReceiveActivationEmailResponse,
  ResetPasswordPayload,
  ResetPasswordResponse,
  SendActivationEmailPayload,
  SendActivationEmailResponse,
  SendDiagnosticAssignmentEmailPayload,
  SendDiagnosticAssignmentEmailResponse,
  SignInPayload,
  SignInResponse,
} from 'store/api/authApi/types';
import { resetGroups } from 'store/features/navigationSlice';

import { baseAuthQueryWithReauth } from '../../helpers';

import { setShouldBeRedirected, signOut } from '../../features/authSlice';
import { profileApi } from '../profileApi/profileApi';

export const authApi = createApi({
  baseQuery: baseAuthQueryWithReauth,
  endpoints: (builder) => ({
    signIn: builder.mutation<SignInResponse, SignInPayload>({
      query: (credentials) => ({
        url: '/auth/login/',
        method: 'POST',
        body: credentials,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          localStorage.setItem('access', data.access);
          localStorage.setItem('refresh', data.refresh);
          dispatch(setShouldBeRedirected(true));
          dispatch(profileApi.endpoints.getMyProfile.initiate(undefined, { subscribe: false, forceRefetch: true }));
        } catch (e) {}
      },
    }),

    signOut: builder.mutation<void, void>({
      query: () => ({ url: '/auth/log-out/', method: 'DELETE' }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        localStorage.clear();

        authApi.util.resetApiState();
        profileApi.util.resetApiState();

        dispatch(signOut());
        dispatch(resetGroups());
      },
    }),

    changePassword: builder.mutation<ChangePasswordResponse, ChangePasswordPayload>({
      query: (credentials) => ({
        method: 'POST',
        url: '/current-user/change-password/',
        body: credentials,
      }),
    }),

    resetPassword: builder.mutation<ResetPasswordResponse, ResetPasswordPayload>({
      query: (credentials) => ({
        method: 'POST',
        url: '/auth/reset-password/',
        body: credentials,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then((data) => {
            toast.success(data?.data?.detail);
          })
          .catch((error) => toast.error(error?.data?.detail || t('general.error')));
      },
    }),

    activateUser: builder.mutation<ActivateUsersResponse, ActivateUsersPayload>({
      query: (credentials) => ({
        method: 'POST',
        url: '/auth/activate/',
        body: credentials,
      }),
    }),

    receiveActivationEmail: builder.mutation<ReceiveActivationEmailResponse, ReceiveActivationEmailPayload>({
      query: (tokens) => ({
        method: 'POST',
        url: '/auth/activation-email/',
        body: tokens,
      }),
    }),

    sendActivationEmail: builder.mutation<SendActivationEmailResponse, SendActivationEmailPayload>({
      query: ({ userId }) => ({
        method: 'POST',
        url: '/auth/emails/signup/resend/',
        body: {
          user_id: userId,
        },
      }),
    }),
    sendDiagnosticAssignmentEmail: builder.mutation<
      SendDiagnosticAssignmentEmailResponse,
      SendDiagnosticAssignmentEmailPayload
    >({
      query: ({ assigneeIds, assignmentVersionName }) => ({
        method: 'POST',
        url: '/auth/emails/signup/assignment/',
        body: {
          assignment_version_name: assignmentVersionName,
          assignee_ids: assigneeIds,
          send: true,
        },
      }),
    }),
  }),
});

export const {
  useSignInMutation,
  useResetPasswordMutation,
  useSignOutMutation,
  useActivateUserMutation,
  useChangePasswordMutation,
  useReceiveActivationEmailMutation,
  useSendActivationEmailMutation,
  useSendDiagnosticAssignmentEmailMutation,
} = authApi;
