import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useSearchParams } from 'react-router-dom';

import { Box } from '@mui/material';

import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import Accelerants from 'components/Dashboard/components/Accelerants/Accelerants';
import Context from 'components/Dashboard/components/Context/Context';
import Ecosystem from 'components/Dashboard/components/Ecosystem/Ecosystem';
import HeaderInfo from 'components/Dashboard/components/HeaderInfo/HeaderInfo';
import ComparisonHeaderInfo from 'components/Dashboard/components/HeaderInfo/components/ComparisonHeaderInfo/ComparisonHeaderInfo';
import SCurve from 'components/Dashboard/components/SCurve/SCurve';
import Loader from 'components/Loader/Loader';
import PageTitle from 'components/PageTitle/PageTitle';
import Tabs from 'components/Tabs/Tabs';
import ThreeDotsMenu from 'components/ThreeDotsMenu/ThreeDotsMenu';

import DashboardComparisonModal from './components/DashboardComparisonModal/DashboardComparisonModal';
import GroupSelector from './components/Selectors/components/GroupSelector/GroupSelector';

import { useAppSelector } from 'hooks/redux';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useToggle } from 'hooks/useToggle';

import {
  useGetComparisonListQuery,
  useGetCompletionByIdQuery,
  useGetDashboardGroupsQuery,
  useGetLastCompletionQuery,
} from 'store/api/assessmentApi/assessmentApi';
import { GetDashboardGroupsResponse } from 'store/api/assessmentApi/types';
import {
  useDownloadGroupPdfMutation,
  useDownloadUserPdfMutation,
  useGetContextsQuery,
} from 'store/api/mappingsApi/mappingsApi';

import { checkPermission } from 'helpers/checkUserPermission';

import { MOBILE_BREAKPOINT } from 'constants/';

import { DashboardSearchParams, DashboardType, SelectedGroups, Tab } from 'types/dashboardTypes';
import { ButtonFill, ButtonSize, Size } from 'types/enums';
import { PermissionsAction } from 'types/permissionsTypes';

import { ReactComponent as Compare } from 'assets/images/compare.svg';

import styles from './DashboardRegular.module.scss';

type PageWrapperProps = {
  isComparisonMode: boolean;
  children: ReactNode;
  setActiveMobileTab?: Dispatch<SetStateAction<Tab>>;
  groups?: GetDashboardGroupsResponse;
  isLoadingGroups: boolean;
};

const PageWrapper = ({ isComparisonMode, children, setActiveMobileTab, groups, isLoadingGroups }: PageWrapperProps) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: MOBILE_BREAKPOINT,
  });
  const { user } = useAppSelector((state) => state.auth);
  const comparisonType = useAppSelector((state) => state.permissions.comparisonType);
  const globalDashboardType = useAppSelector((state) => state.permissions.dashboardType);
  const [searchParams, setSearchParams] = useSearchParams();
  const userId = searchParams.get(DashboardSearchParams.USER_1) || '';
  const groupId = searchParams.get(DashboardSearchParams.GROUP_1) || '';
  const completion1 = searchParams.get(DashboardSearchParams.COMPLETION_1) || '';
  const [isSelectAnotherUser, toggleIsSelectAnotherUser] = useToggle(!!userId);
  const [isComparisonModalOpen, toggleComparisonModalOpen] = useToggle(false);
  const dashboardType =
    globalDashboardType === DashboardType.GROUP && userId ? DashboardType.USER : globalDashboardType;
  const [downloadGroupPdf, { isLoading: isLoadingDownloadGroupPdf }] = useDownloadGroupPdfMutation();
  const [downloadUserPdf, { isLoading: isLoadingDownloadUserPdf }] = useDownloadUserPdfMutation();

  const { data: assessments2 = [] } = useGetComparisonListQuery(
    {
      comparisonType,
      userId: user?.id || '',
      comparedAssessmentId: completion1,
    },
    { skip: !completion1 },
  );

  const options = [
    {
      title: isComparisonMode ? t('dashboard.new_comparison') : t('dashboard.comparison'),
      icon: <Compare />,
      action: toggleComparisonModalOpen,
    },
    ...(assessments2.length > 0
      ? [
          {
            title: t('assessment.compare_to_previous_results'),
            icon: <Compare />,
            action: () => {
              if (assessments2[assessments2.length - 1]?.completed_assessment_id) {
                searchParams.set(
                  DashboardSearchParams.COMPLETION_2,
                  assessments2[assessments2.length - 1].completed_assessment_id,
                );

                setSearchParams(searchParams);
              }
            },
          },
        ]
      : []),
  ];

  const handleDownloadGroupPdf = () => {
    downloadGroupPdf(groupId);
  };

  const handleDownloadUserPdf = () => {
    if (user) {
      downloadUserPdf({
        id: user.id,
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
      });
    }
  };

  const renderElement = (element: ReactNode) => {
    if (isMobile && dashboardType === DashboardType.GROUP) {
      const isSelectingGroup = !groupId && !isSelectAnotherUser;
      const isSelectingAnotherUser = isSelectAnotherUser && !userId;

      if (isSelectingGroup || isSelectingAnotherUser) {
        return null;
      }
    }

    return element;
  };

  return (
    <div className={styles.Page}>
      <DashboardComparisonModal
        open={isComparisonModalOpen}
        close={toggleComparisonModalOpen}
        groups={groups}
        isLoadingGroups={isLoadingGroups}
      />

      <PageTitle
        title={t('dashboard.page_title')}
        size={Size.Sm}
        info={renderElement(
          dashboardType && (
            <div className={styles.HeaderInfoContainer}>
              <HeaderInfo dashboardType={dashboardType} isComparisonMode={isComparisonMode} />
            </div>
          ),
        )}
        spacingTop={isComparisonMode ? Size.Sm : Size.Lg}
        spacingBottom={isComparisonMode ? Size.Lg : Size.Sm}
        hideBorder
      >
        {!isMobile && !isSelectAnotherUser && dashboardType && (
          <ButtonStyled
            size={ButtonSize.Small}
            fill={ButtonFill.Outlined}
            onClick={globalDashboardType === DashboardType.USER ? handleDownloadUserPdf : handleDownloadGroupPdf}
            loading={globalDashboardType === DashboardType.USER ? isLoadingDownloadUserPdf : isLoadingDownloadGroupPdf}
            disabled={globalDashboardType === DashboardType.USER ? isLoadingDownloadUserPdf : isLoadingDownloadGroupPdf}
          >
            {t('dashboard.download_report')}
          </ButtonStyled>
        )}
        {!isMobile && comparisonType && (
          <>
            {options.length > 1 ? (
              <ThreeDotsMenu
                triggerComponent={
                  <ButtonStyled
                    size={ButtonSize.Small}
                    fill={ButtonFill.Contained}
                    onClick={toggleComparisonModalOpen}
                    icon={<Compare />}
                  >
                    {t('dashboard.comparison')}
                  </ButtonStyled>
                }
                options={options}
              />
            ) : (
              <ButtonStyled
                size={ButtonSize.Small}
                fill={ButtonFill.Contained}
                onClick={toggleComparisonModalOpen}
                icon={<Compare />}
              >
                {t('dashboard.comparison')}
              </ButtonStyled>
            )}
          </>
        )}
      </PageTitle>

      {isComparisonMode && dashboardType && (
        <div className={styles.ComparisonHeaderInfo}>
          <ComparisonHeaderInfo dashboardType={dashboardType} />
        </div>
      )}

      {!isComparisonMode && dashboardType && globalDashboardType !== DashboardType.USER && (
        <GroupSelector
          dashboardType={dashboardType}
          isSelectAnotherUser={isSelectAnotherUser}
          toggleIsSelectAnotherUser={toggleIsSelectAnotherUser}
          setActiveMobileTab={setActiveMobileTab}
          groups={groups}
          isLoadingGroups={isLoadingGroups}
        />
      )}

      {renderElement(children)}
    </div>
  );
};

const DashboardRegular = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: MOBILE_BREAKPOINT,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedGroupId = useAppSelector((state) => state.navigation.selectedGroupId);
  const selectedOrganizationId = useAppSelector((state) => state.navigation.selectedOrganizationId);
  const user = useAppSelector((state) => state.auth.user);
  const globalDashboardType = useAppSelector((state) => state.permissions.dashboardType);
  const { own_results } = useAppSelector((state) => state.permissions.permissions);
  const [activeMobileTab, setActiveMobileTab] = useState(Tab.SCurve);

  const completionId1 = searchParams.get(DashboardSearchParams.COMPLETION_1) || '';
  const completionId2 = searchParams.get(DashboardSearchParams.COMPLETION_2) || '';
  const groupId1 = searchParams.get(DashboardSearchParams.GROUP_1) || '';
  const groupId2 = searchParams.get(DashboardSearchParams.GROUP_2) || '';
  const userId1 = searchParams.get(DashboardSearchParams.USER_1) || '';
  const dashboardType =
    globalDashboardType === DashboardType.GROUP && userId1 ? DashboardType.USER : globalDashboardType;

  const { data: contextData, isLoading: isLoadingContext } = useGetContextsQuery(
    { completionId: completionId1 },
    { skip: !completionId1 },
  );

  const isComparisonMode = !!(completionId1 && completionId2);
  const isOwnDashboard = dashboardType === DashboardType.USER;
  const isGroupDashboard = dashboardType === DashboardType.GROUP;

  const [requestId1, requestId2] = dashboardType
    ? {
        [DashboardType.GROUP]: [groupId1, groupId2],
        [DashboardType.USER]: [completionId1, completionId2],
      }[dashboardType]
    : ['', ''];

  const {
    data: completionLeft,
    isLoading: isLoadingCompletionLeft,
    isFetching: isFetchingCompletionLeft,
  } = useGetCompletionByIdQuery(
    { id: completionId1, groupId: isGroupDashboard ? groupId1 : selectedGroupId },
    { skip: !completionId1 },
  );

  const { data: lastCompletion, isLoading: isLoadingLastCompletion } = useGetLastCompletionQuery(
    { userId: user?.id || '' },
    { skip: !user?.id || globalDashboardType === DashboardType.GROUP },
  );

  const {
    data: groups,
    isLoading: isLoadingGroups,
    isFetching: isFetchingGroups,
  } = useGetDashboardGroupsQuery(selectedGroupId, {
    skip: !selectedOrganizationId || globalDashboardType === DashboardType.USER,
  });

  const selectedGroups: SelectedGroups = [
    groups?.find((item) => item.id === groupId1),
    groups?.find((item) => item.id === groupId2),
  ];

  const isContextAvailable = !isComparisonMode && isOwnDashboard && !!contextData?.show_context;
  const isLoading =
    isLoadingLastCompletion ||
    isLoadingCompletionLeft ||
    isFetchingCompletionLeft ||
    isLoadingGroups ||
    isFetchingGroups ||
    isLoadingContext;

  const mobileTabs = isMobile
    ? [
        { title: t('assessment.steps.scurve.title') },
        { title: t('dashboard.accelerants.title') },
        { title: t('dashboard.ecosystem.title') },
        ...(isContextAvailable ? [{ title: t('dashboard.context.title') }] : []),
      ]
    : null;

  useEffect(() => {
    if (lastCompletion?.id && isOwnDashboard && !isComparisonMode) {
      searchParams.set(DashboardSearchParams.COMPLETION_1, lastCompletion.id);
      setSearchParams(searchParams);
    }
  }, [lastCompletion, searchParams, setSearchParams, isOwnDashboard, isComparisonMode]);

  useDocumentTitle([t('page_titles.dashboard_regular')]);

  if (isLoading) {
    return (
      <PageWrapper
        isComparisonMode={isComparisonMode}
        groups={groups}
        isLoadingGroups={isLoadingGroups || isFetchingGroups}
      >
        <Box m={8}>
          <Loader flexCenter size="md" />
        </Box>
      </PageWrapper>
    );
  }

  if (!dashboardType || (userId1 === user?.id && !checkPermission(own_results, [PermissionsAction.READ]))) {
    return (
      <PageWrapper
        isComparisonMode={isComparisonMode}
        groups={groups}
        isLoadingGroups={isLoadingGroups || isFetchingGroups}
      >
        <div className={styles.NoResults}>
          <span>{t('dashboard.no_permission')}</span>
        </div>
      </PageWrapper>
    );
  }

  if (!requestId1 && !completionLeft) {
    return (
      <PageWrapper
        isComparisonMode={isComparisonMode}
        groups={groups}
        isLoadingGroups={isLoadingGroups || isFetchingGroups}
      >
        <div className={styles.NoResults}>
          <span>{t(isOwnDashboard ? 'dashboard.empty_result_own' : 'dashboard.empty_result_selected')}</span>
        </div>
      </PageWrapper>
    );
  }

  const renderContent = () => {
    if (isMobile && mobileTabs) {
      return (
        <>
          <Tabs tabs={mobileTabs} activeTab={activeMobileTab} changeTab={setActiveMobileTab} underline spaceBetween />
          {
            [
              <SCurve
                key={Tab.SCurve}
                dashboardType={dashboardType}
                selectedGroups={selectedGroups}
                requestId1={requestId1}
                requestId2={requestId2}
              />,
              <Accelerants
                key={Tab.Accelerants}
                dashboardType={dashboardType}
                selectedGroups={selectedGroups}
                requestId1={requestId1}
                requestId2={requestId2}
              />,
              <Ecosystem
                key={Tab.Ecosystem}
                dashboardType={dashboardType}
                selectedGroups={selectedGroups}
                requestId1={requestId1}
                requestId2={requestId2}
              />,
              ...(isContextAvailable ? [<Context key={Tab.Context} completionId={completionId1} />] : []),
            ][activeMobileTab]
          }
        </>
      );
    }

    return (
      <>
        <SCurve
          dashboardType={dashboardType}
          selectedGroups={selectedGroups}
          requestId1={requestId1}
          requestId2={requestId2}
        />

        <Accelerants
          dashboardType={dashboardType}
          selectedGroups={selectedGroups}
          requestId1={requestId1}
          requestId2={requestId2}
        />

        <Ecosystem
          dashboardType={dashboardType}
          selectedGroups={selectedGroups}
          requestId1={requestId1}
          requestId2={requestId2}
        />

        {isContextAvailable && <Context completionId={completionId1} />}
      </>
    );
  };

  return (
    <PageWrapper
      isComparisonMode={isComparisonMode}
      setActiveMobileTab={setActiveMobileTab}
      groups={groups}
      isLoadingGroups={isLoadingGroups || isFetchingGroups}
    >
      {renderContent()}
    </PageWrapper>
  );
};

export default DashboardRegular;
