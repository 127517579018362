import { useState } from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import { useAppSelector } from 'hooks/redux';

import { checkPermission } from 'helpers/checkUserPermission';

import { PermissionsAction } from 'types/permissionsTypes';

import styles from '../../Table.module.scss';

type AssignedToCellProps = {
  value?: string[];
  formattedValue: string;
};

const AssignedToCell = ({ value, formattedValue }: AssignedToCellProps) => {
  const { own_results, group_dashboard_comparison, other_users_dashboard_comparison } = useAppSelector(
    (state) => state.permissions.permissions,
  );
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const assignedToCount = value?.length || 0;
  const isAssignedToTooltipVisible =
    !checkPermission(own_results, [PermissionsAction.READ]) ||
    checkPermission(other_users_dashboard_comparison, [PermissionsAction.READ]) ||
    checkPermission(group_dashboard_comparison, [PermissionsAction.READ]);
  const isShowOtherAssignees = assignedToCount > 1 && isAssignedToTooltipVisible;

  const handleAssignedToClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setTooltipIsOpen(!tooltipIsOpen);
  };

  return (
    <ClickAwayListener onClickAway={() => setTooltipIsOpen(false)}>
      <div>
        <LightTooltip
          placement="bottom-start"
          disableHoverListener
          disableFocusListener
          disableTouchListener
          open={Boolean(assignedToCount) && tooltipIsOpen}
          onOpen={() => setTooltipIsOpen(true)}
          onClose={() => setTooltipIsOpen(false)}
          title={
            <div>
              {value?.map((item, index) => (
                <p key={index} className={styles.TooltipText}>
                  {item}
                </p>
              ))}
            </div>
          }
        >
          <button
            className={styles.AssignedToContainer}
            onClick={isShowOtherAssignees ? handleAssignedToClick : undefined}
          >
            <span className={styles.AssignedTo}>{formattedValue}</span>
            {isShowOtherAssignees && <span className={styles.AssignedToMore}>+{assignedToCount - 1}</span>}
          </button>
        </LightTooltip>
      </div>
    </ClickAwayListener>
  );
};

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'var(--matte-black)',
    fontSize: '11px',
    padding: '10px 16px',
    boxShadow: '4px 4px 31px rgba(40, 40, 40, 0.09)',
    borderRadius: '8px',
    maxHeight: '134px',
    overflowY: 'auto',

    '@media screen and (max-width: 932px) {': {
      maxHeight: '194px',
    },

    'p + p': {
      marginTop: '16px',
    },
  },
}));

export default AssignedToCell;
