const ROUTES = {
  LOGIN: '/login',
  SIGNUP: '/sign-up',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset',
  ONBOARDING_PROFILE: '/onboarding/profile',

  DASHBOARD_REGULAR: '/dashboard/regular',
  DASHBOARD_NAVIGATIONAL: '/dashboard/navigational',

  PERSONAL_PROFILE_SETTINGS: '/personal-profile-settings',
  CHANGE_PASSWORD: '/change-password',

  DIAGNOSTIC_LIST: '/diagnostic/list',
  DIAGNOSTIC_RESULTS: '/diagnostic/results',
  DIAGNOSTIC_CREATE: '/diagnostic/create',
  DIAGNOSTIC_ASSIGNMENT_CREATE: '/diagnostic/assignment/create',
  DIAGNOSTIC_DETAILS: (id = ':id') => `/diagnostic/${id}`,
  DIAGNOSTIC_ASSIGNMENT_DETAILS: (id = ':id') => `/diagnostic/assignment/${id}`,
  DIAGNOSTIC_COMPARE: (id1 = ':id1', id2 = ':id2') => `/diagnostic/compare/${id1}/${id2}`,
  DIAGNOSTIC_EDIT: (id = ':id') => `/diagnostic/edit/${id}`,
  DIAGNOSTIC_ASSIGNMENT_EDIT: (id = ':id') => `/diagnostic/assignment/edit/${id}`,
  DIAGNOSTIC_COMPLETION: (id = ':id') => `/diagnostic/completion/${id}`,
  DIAGNOSTIC_TAKE: (id1 = ':id1', id2 = ':id2') => `/diagnostic/take/${id1}/${id2}`,

  ROLE_LIST: '/roles/list',
  ROLE_ADD: '/roles/add',
  ROLE_PERMISSIONS: '/roles/permission',
  ROLE_EDIT: (id = ':id') => `/roles/edit/${id}`,

  GROUP_LIST: '/groups/list',
  GROUP_ADD: '/groups/add',
  GROUP_HIERARCHY: '/groups/hierarchy',
  GROUP_EDIT: (id = ':id') => `/groups/edit/${id}`,
  GROUP_MANAGE: (id = ':id') => `/groups/manage/${id}`,

  USER_LIST: '/users/list',
  USER_ADD: '/users/add',
  USER_ADD_CSV: '/users/add-csv',
  USER_EDIT: (id = ':id') => `/users/edit/${id}`,
  USER_PROFILE: (id = ':id') => `/users/profile/${id}`,
};

export default ROUTES;
