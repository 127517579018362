import Level from './components/Level/Level';

import { IGroupsHierarchyList } from 'types/groupHierarchyTypes';
import { GroupsHierarchyResponse } from 'types/groupHierarchyTypes';

import styles from './GroupHierarchyPicker.module.scss';

type Props = {
  data: GroupsHierarchyResponse;
  list: IGroupsHierarchyList;
  onSelectEntity: (value: string, entity: string) => void;
  selectUsers: (value: string[], selected: boolean) => void;
};

// Using this component for selecting group and user for Assessment Assign

const GroupHierarchyPicker = ({ data, list, onSelectEntity, selectUsers }: Props) => (
  <div>
    <ul className={styles.GroupHierarchy}>
      {data.items.map((element, index) => (
        <Level
          index={index}
          data={element}
          key={element.id}
          list={list}
          onSelectEntity={onSelectEntity}
          selectUsers={selectUsers}
        />
      ))}
    </ul>
  </div>
);

export default GroupHierarchyPicker;
