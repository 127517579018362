import { GridColDef, GridInitialState, GridRowParams } from '@mui/x-data-grid';
import orderBy from 'lodash/orderBy';

import DataTable from 'components/DataTable/DataTable';

import { IAssessmentListItem, ISort } from 'types/assessmentTypes';

type Props = {
  sort: ISort;
  search: string;
  page: number;
  size: number;
  total?: number;
  isFetching: boolean;
  isLoading: boolean;
  assessments: IAssessmentListItem[];
  columns: GridColDef[];
  onPageChange: (page: number) => void;
  onRowClick?: (arg0: GridRowParams) => void;
  newDesign?: boolean;
  emptyResultText?: string;
  initialState?: GridInitialState;
};

const AssessmentListTable = ({
  isFetching,
  isLoading,
  page,
  total,
  assessments,
  onPageChange,
  columns,
  onRowClick,
  sort,
  newDesign,
  emptyResultText,
  initialState,
}: Props) => {
  const items = orderBy(assessments, [(item) => item.name.toLowerCase()], [sort.sortType]);
  return (
    <DataTable
      data={{ items, total: total || assessments.length }}
      columns={columns}
      isLoading={isFetching || isLoading}
      page={page}
      changePage={onPageChange}
      onRowClick={onRowClick}
      rowHoverPointer
      newDesign={newDesign}
      emptyResultText={emptyResultText}
      initialState={initialState}
    />
  );
};

export default AssessmentListTable;
