import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import ShadowBlock from 'components/PageLayout/components/ShadowBlock/ShadowBlock';

import ROUTES from 'router/routes';

import { ButtonFill } from 'types/enums';

import { ReactComponent as Hourglass } from 'assets/images/hourglass.svg';

import styles from './DiagnosticExpired.module.scss';

const DiagnosticExpired = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <ShadowBlock>
      <div className={styles.Icon}>
        <Hourglass />
      </div>
      <div className={styles.Title}>
        <span>{t('assessment.diagnostic_expired_title')}</span>
      </div>
      <div className={styles.Description}>
        <span>{t('assessment.diagnostic_expired_description')}</span>
      </div>
      <ButtonStyled fullWidth fill={ButtonFill.Contained} onClick={() => navigate(ROUTES.DIAGNOSTIC_LIST)}>
        {t('general.back')}
      </ButtonStyled>
    </ShadowBlock>
  );
};

export default DiagnosticExpired;
