import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack } from '@mui/material';

import Select from 'components/Inputs/Select/Select';
import ModalWrapper from 'components/Modals/ModalWrapper';

import { useAppSelector } from 'hooks/redux';

import {
  useGetComparisonListGroupQuery,
  useGetComparisonListQuery,
  useGetComparisonUsersQuery,
} from 'store/api/assessmentApi/assessmentApi';
import { GetDashboardGroupsResponse } from 'store/api/assessmentApi/types';

import { compareInGroupUsersAssessmentModalResolver } from 'helpers/validations';

import { getAssessmentOptions, getAssessmentOptionsGroup, getGroupOptions, getUserOptions } from '../../helpers';

import { ComparisonType } from 'types/assessmentTypes';
import { CardVariants, DashboardSearchParams } from 'types/dashboardTypes';
import { ModalProps } from 'types/modalTypes';

import OptionCard from '../../../OptionCard/OptionCard';

interface Props extends ModalProps {
  groups?: GetDashboardGroupsResponse;
  isLoadingGroups: boolean;
}

type FormValues = {
  option1: {
    group: string;
    user: string;
    assessment: string;
  };
  option2: {
    group: string;
    user: string;
    assessment: string;
  };
};

const initialState = {
  option1: {
    group: '',
    user: '',
    assessment: '',
  },
  option2: {
    group: '',
    user: '',
    assessment: '',
  },
};

const GroupComparisonModal = ({ open, close, groups, isLoadingGroups }: Props) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const comparisonType = useAppSelector((state) => state.permissions.comparisonType);

  const { handleSubmit, formState, control, reset, resetField, watch } = useForm<FormValues>({
    resolver: yupResolver(compareInGroupUsersAssessmentModalResolver(t)),
    defaultValues: initialState,
    mode: 'all',
  });

  const group1Value = watch('option1.group');
  const group2Value = watch('option2.group');
  const user1Value = watch('option1.user');
  const user2Value = watch('option2.user');
  const assessment1Value = watch('option1.assessment');

  const { data: users1 = [], isFetching: isFetchingUsers1 } = useGetComparisonUsersQuery(
    {
      groupId: group1Value,
    },
    { skip: !group1Value },
  );

  const { data: assessments1 = [], isFetching: isFetchingAssessments1 } = useGetComparisonListQuery(
    {
      comparisonType,
      userId: user1Value,
    },
    { skip: !user1Value },
  );

  const { data: assessmentGroup1 = [], isFetching: isFetchingAssessmentGroup1 } = useGetComparisonListGroupQuery(
    {
      groupId: group1Value,
    },
    { skip: Boolean(!group1Value || user1Value) },
  );

  const { data: users2 = [], isFetching: isFetchingUsers2 } = useGetComparisonUsersQuery(
    {
      groupId: group2Value,
    },
    { skip: !group2Value },
  );

  const { data: assessments2 = [], isFetching: isFetchingAssessments2 } = useGetComparisonListQuery(
    {
      comparisonType,
      userId: user2Value,
      comparedAssessmentId: assessment1Value,
    },
    { skip: !user2Value || !assessment1Value },
  );

  const { data: assessmentGroup2 = [], isFetching: isFetchingAssessmentGroup2 } = useGetComparisonListGroupQuery(
    {
      groupId: group2Value,
    },
    { skip: Boolean(!group2Value || user2Value) },
  );

  const onClose = () => {
    close?.();
    reset(initialState);
  };

  const onSubmit: SubmitHandler<FormValues> = ({ option1, option2 }) => {
    if (user1Value) {
      searchParams.set(DashboardSearchParams.USER_1, option1.user);
    } else {
      searchParams.delete(DashboardSearchParams.USER_1);
    }

    if (user2Value) {
      searchParams.set(DashboardSearchParams.USER_2, option2.user);
    } else {
      searchParams.delete(DashboardSearchParams.USER_2);
    }

    const leftAssessmentId = assessmentGroup1.find((item) => item.completed_assessment_id === option1.assessment)
      ?.assessment_id;
    const rightAssessmentId = assessmentGroup2.find((item) => item.completed_assessment_id === option2.assessment)
      ?.assessment_id;

    if (leftAssessmentId && rightAssessmentId) {
      searchParams.set(DashboardSearchParams.ASSESSMENT_1, leftAssessmentId);
      searchParams.set(DashboardSearchParams.ASSESSMENT_2, rightAssessmentId);
    }

    searchParams.set(DashboardSearchParams.COMPLETION_1, option1.assessment);
    searchParams.set(DashboardSearchParams.COMPLETION_2, option2.assessment);
    searchParams.set(DashboardSearchParams.GROUP_1, option1.group);
    searchParams.set(DashboardSearchParams.GROUP_2, option2.group);
    setSearchParams(searchParams);
    onClose();
  };

  useEffect(() => {
    resetField('option1.user');
    resetField('option1.assessment');
  }, [group1Value, resetField]);

  useEffect(() => {
    resetField('option1.assessment');
  }, [user1Value, resetField]);

  useEffect(() => {
    resetField('option2.user');
    resetField('option2.assessment');
  }, [group2Value, resetField]);

  useEffect(() => {
    resetField('option2.assessment');
  }, [user2Value, resetField]);

  useEffect(() => {
    resetField('option2.group');
    resetField('option2.user');
    resetField('option2.assessment');
  }, [group1Value, user1Value, assessment1Value, resetField]);

  return (
    <ModalWrapper
      title={t('assessment.comparison')}
      subtitle={t('dashboard.comparison_modal.description')}
      open={open}
      action={handleSubmit(onSubmit)}
      actionTitle={t('general.compare')}
      actionDisabled={!formState.isValid}
      close={onClose}
      cancelTitle={t('general.cancel')}
      large
    >
      <Box mb={1.5} display="flex" justifyContent="center" width="100%"></Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="row">
          <OptionCard variant={CardVariants.Blue} title={t('dashboard.comparison_modal.option_1_title')}>
            <Box mt={1.5}>
              <Controller
                control={control}
                name="option1.group"
                render={({ field: { onChange, value } }) => (
                  <Select
                    required
                    value={value}
                    data={groups && getGroupOptions(groups)}
                    label={t('inputs.group')}
                    placeholder={t('inputs.group_placeholder')}
                    onSelect={onChange}
                    isLoading={isLoadingGroups}
                  />
                )}
              />
            </Box>

            {comparisonType === ComparisonType.IndividualUser && (
              <Box mt={1.5}>
                <Controller
                  control={control}
                  name="option1.user"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      value={value}
                      data={getUserOptions(users1)}
                      label={t('inputs.user')}
                      placeholder={t('inputs.select_user')}
                      onSelect={onChange}
                      isLoading={isFetchingUsers1}
                      disabled={!group1Value}
                    />
                  )}
                />
              </Box>
            )}

            <Box mt={1.5} mb={-2}>
              <Controller
                control={control}
                name="option1.assessment"
                render={({ field: { onChange, value } }) => (
                  <Select
                    required
                    value={value}
                    data={user1Value ? getAssessmentOptions(assessments1) : getAssessmentOptionsGroup(assessmentGroup1)}
                    label={t('inputs.diagnostic')}
                    placeholder={t('inputs.select_diagnostic')}
                    onSelect={onChange}
                    isLoading={user1Value ? isFetchingAssessments1 : isFetchingAssessmentGroup1}
                    disabled={!group1Value}
                  />
                )}
              />
            </Box>
          </OptionCard>

          <OptionCard variant={CardVariants.Yellow} title={t('dashboard.comparison_modal.option_2_title')}>
            <Box mt={1.5}>
              <Controller
                control={control}
                name="option2.group"
                render={({ field: { onChange, value } }) => (
                  <Select
                    required
                    value={value}
                    data={groups && getGroupOptions(groups)}
                    label={t('inputs.group')}
                    placeholder={t('inputs.group_placeholder')}
                    onSelect={onChange}
                    isLoading={isLoadingGroups}
                    disabled={!assessment1Value}
                  />
                )}
              />
            </Box>

            {comparisonType === ComparisonType.IndividualUser && (
              <Box mt={1.5}>
                <Controller
                  control={control}
                  name="option2.user"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      value={value}
                      data={getUserOptions(users2)}
                      label={t('inputs.user')}
                      placeholder={t('inputs.select_user')}
                      onSelect={onChange}
                      isLoading={isFetchingUsers2}
                      disabled={!group2Value || !user1Value}
                    />
                  )}
                />
              </Box>
            )}

            <Box mt={1.5} mb={-2}>
              <Controller
                control={control}
                name="option2.assessment"
                render={({ field: { onChange, value } }) => (
                  <Select
                    required
                    value={value}
                    data={user2Value ? getAssessmentOptions(assessments2) : getAssessmentOptionsGroup(assessmentGroup2)}
                    label={t('inputs.diagnostic')}
                    placeholder={t('inputs.select_diagnostic')}
                    onSelect={onChange}
                    isLoading={user2Value ? isFetchingAssessments2 : isFetchingAssessmentGroup2}
                    disabled={!group2Value}
                  />
                )}
              />
            </Box>
          </OptionCard>
        </Stack>
      </form>
    </ModalWrapper>
  );
};

export default GroupComparisonModal;
