import { IGroupHierarchyItem } from 'types/groupHierarchyTypes';

export const findElement = (elements: IGroupHierarchyItem[]) => {
  let isElementFounded = false;

  function recurse(elements: IGroupHierarchyItem[]) {
    for (let childrenElement of elements) {
      if (childrenElement.children && childrenElement.children.some((element) => element.matches_search_term)) {
        isElementFounded = true;
        break;
      } else if (childrenElement.children) {
        recurse(childrenElement.children);
        if (isElementFounded) {
          break;
        }
      }
    }
  }

  recurse(elements);
  return isElementFounded;
};

export const getFlatSearchHierarchy = (data: IGroupHierarchyItem[]) => {
  const flatTree = (object: IGroupHierarchyItem): IGroupHierarchyItem[] => [
    { ...object },
    ...object.children.flatMap(flatTree),
  ];
  const find = (item: IGroupHierarchyItem) => item.id === start?.parent_id;

  const flatHierarchy = data.flatMap(flatTree);
  const foundGroup = flatHierarchy.find((item) => item.matches_search_term);
  const results: IGroupHierarchyItem[] = [];
  let start = foundGroup;

  while (start?.parent_id) {
    const item = flatHierarchy.find(find);
    start = item;
    if (item) {
      results.push(item);
    }
  }

  return results;
};

export const getGroupsWithAllUsersInSelected = (
  data: IGroupHierarchyItem[],
  selectedUserIds: string[],
  groupList: string[],
) => {
  const groupIdsAdd = new Set<string>();
  const groupIdsRemove = new Set<string>();

  const processGroup = (group: IGroupHierarchyItem) => {
    const allUsersExist = group.users.length && group.users.every((user) => selectedUserIds.includes(user.id));

    if (allUsersExist) {
      groupIdsAdd.add(group.id);
    } else {
      if (groupList.includes(group.id)) {
        groupIdsRemove.add(group.id);
      }
    }

    if (group.children.length) {
      group.children.forEach((child) => processGroup(child));
    }
  };

  data.forEach((group) => processGroup(group));

  groupList.forEach(groupIdsAdd.add, groupIdsAdd);

  return { add: Array.from(groupIdsAdd), remove: Array.from(groupIdsRemove) };
};
