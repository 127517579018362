import { ReactElement, ReactNode } from 'react';

import classNames from 'classnames';

import NewSearch from 'components/Inputs/NewSearch/NewSearch';
import Search from 'components/Inputs/Search/Search';
import NewSortButton from 'components/NewSortButton/NewSortButton';
import SortButton from 'components/SortButton/SortButton';

import { Size } from 'types/enums';
import { TabsProps } from 'types/tabsTypes';

import Tabs from '../Tabs/Tabs';

import styles from './PageTitle.module.scss';

type Props = {
  title?: string | ReactElement;
  size?: typeof Size.Sm | typeof Size.Lg;
  info?: ReactNode;
  searchPlaceholder?: string;
  children?: ReactNode;
  search?: string;
  sort?: string;
  controls?: boolean;
  hideBorder?: boolean;
  tabsProps?: TabsProps;
  spacingTop?: typeof Size.Sm | typeof Size.Lg;
  spacingBottom?: typeof Size.Sm | typeof Size.Lg;
  onSort?: () => void;
  onSearch?: (value: string) => void;
  middleChildren?: ReactElement | null;
  titleTopColumn?: boolean;
  newSearch?: boolean;
  searchDisabled?: boolean;
};

const PageTitle = ({
  title,
  size = Size.Lg,
  spacingTop = Size.Sm,
  spacingBottom = Size.Lg,
  info,
  searchPlaceholder,
  children,
  search,
  sort,
  controls,
  onSort,
  tabsProps,
  hideBorder,
  middleChildren,
  titleTopColumn,
  onSearch = () => {},
  newSearch,
  searchDisabled,
}: Props) => {
  const spacingTopStyles = {
    [Size.Sm]: styles.Sm,
    [Size.Lg]: styles.Lg,
  }[spacingTop];

  const spacingBottomStyles = {
    [Size.Sm]: styles.Sm,
    [Size.Lg]: styles.Lg,
  }[spacingBottom];

  return (
    <div className={styles.PageTitle}>
      {tabsProps ? (
        <Tabs {...tabsProps}>{children}</Tabs>
      ) : (
        <div
          className={classNames(styles.PageTitleTop, spacingTopStyles, {
            [styles.Border]: !hideBorder,
            [styles.Column]: titleTopColumn,
          })}
        >
          <div className={styles.Container}>
            <h3 className={classNames(styles.Title, styles[size])}>{title}</h3>
            {info}
          </div>

          {children && <div className={styles.Controls}>{children}</div>}
        </div>
      )}

      {middleChildren && <div className={styles.PageTitleMiddle}>{middleChildren}</div>}

      {controls && (
        <div className={classNames(styles.PageTitleBottom, spacingBottomStyles)}>
          {newSearch ? (
            <div className={styles.SearchContainer}>
              <NewSearch disabled={searchDisabled} placeholder={searchPlaceholder} value={search} onSearch={onSearch} />
            </div>
          ) : (
            <Search placeholder={searchPlaceholder} value={search} onSearch={onSearch} />
          )}
          {sort && onSort && (
            <div className={styles.ListFilter}>
              {newSearch ? (
                <NewSortButton onSort={onSort} sortType={sort} />
              ) : (
                <SortButton onSort={onSort} sortType={sort} />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PageTitle;
