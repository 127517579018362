import { ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import classNames from 'classnames';

import { useAppSelector } from 'hooks/redux';

import { useSignOutMutation } from 'store/api/authApi/authApi';
import { getCurrentPermissions } from 'store/features/userPermissionsSlice';

import ROUTES from 'router/routes';

import { checkPermission } from 'helpers/checkUserPermission';

import { Permissions, PermissionsAction, permissionsEntity } from 'types/permissionsTypes';

import { ReactComponent as Arrows } from 'assets/images/close-arrow.svg';
import { ReactComponent as LogOut } from 'assets/images/exit.svg';
import { ReactComponent as Group } from 'assets/images/group-editor.svg';
import { ReactComponent as NoteList } from 'assets/images/note-list.svg';
import { ReactComponent as User } from 'assets/images/person.svg';
import { ReactComponent as Role } from 'assets/images/role-editor.svg';
import { ReactComponent as Table } from 'assets/images/table.svg';

import styles from './Menu.module.scss';

interface IMenuElement {
  title: string;
  link: string | string[];
  icon: ReactNode;
  entity: string;
  permissions: PermissionsAction[];
}

const disabledLinks: string[] = [
  ROUTES.ROLE_PERMISSIONS,
  ROUTES.ROLE_ADD,
  ROUTES.USER_ADD,
  ROUTES.ROLE_EDIT(''),
  ROUTES.USER_EDIT(''),
  ROUTES.GROUP_HIERARCHY,
  ROUTES.GROUP_ADD,
  ROUTES.GROUP_MANAGE(''),
  ROUTES.GROUP_EDIT(''),
  ROUTES.ONBOARDING_PROFILE,
  ROUTES.DIAGNOSTIC_CREATE,
  ROUTES.DIAGNOSTIC_ASSIGNMENT_CREATE,
  ROUTES.DIAGNOSTIC_EDIT(''),
  ROUTES.DIAGNOSTIC_ASSIGNMENT_EDIT(''),
  ROUTES.PERSONAL_PROFILE_SETTINGS,
  ROUTES.DIAGNOSTIC_TAKE('', '').slice(0, -1),
  ROUTES.CHANGE_PASSWORD,
];

const Menu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const permissions = useAppSelector(getCurrentPermissions);
  const dashboard = useAppSelector((state) => state.navigation.dashboard);
  const [isHidden, toggle] = useState<boolean>(false);
  const [signOut] = useSignOutMutation();
  const isMobileMenuOpen = useAppSelector((state) => state.navigation.isMobileMenuOpen);

  const signOutHandler = () => {
    signOut().then(() => {
      navigate(ROUTES.LOGIN, { replace: true });
      localStorage.clear();
      window.location.reload();
    });
  };

  const menuElements: IMenuElement[] = useMemo(
    () => [
      {
        title: 'general.dashboard',
        link: [dashboard, ROUTES.DASHBOARD_REGULAR, ROUTES.DASHBOARD_NAVIGATIONAL],
        icon: <Table />,
        entity: 'all',
        permissions: [PermissionsAction.READ],
      },
      {
        title: 'general.role_editor',
        link: ROUTES.ROLE_LIST,
        icon: <Role />,
        entity: permissionsEntity.ROLE,
        permissions: [PermissionsAction.READ],
      },
      {
        title: 'general.group_editor',
        link: ROUTES.GROUP_LIST,
        icon: <Group />,
        entity: permissionsEntity.GROUP,
        permissions: [PermissionsAction.READ],
      },
      {
        title: 'general.user_editor',
        link: ROUTES.USER_LIST,
        icon: <User />,
        entity: permissionsEntity.USER,
        permissions: [PermissionsAction.READ],
      },
      {
        title: 'assessment.diagnostic',
        link: [ROUTES.DIAGNOSTIC_LIST, ROUTES.DIAGNOSTIC_RESULTS],
        icon: <NoteList />,
        entity: permissionsEntity.ASSESSMENT,
        permissions: [PermissionsAction.READ],
      },
    ],
    [dashboard],
  );

  const isDenyRender = disabledLinks.find((link) => pathname.indexOf(link) !== -1);

  const linkStyles = (isActive: boolean) => classNames(styles.Link, { [styles.ActiveLink]: isActive });

  return (
    <aside
      className={classNames(styles.MenuWrapper, {
        [styles.Hidden]: isHidden,
        [styles.Disabled]: isDenyRender,
        [styles.MobileOpen]: isMobileMenuOpen,
      })}
    >
      <nav className={classNames(styles.Menu)}>
        {menuElements
          .filter(
            (el) => el.entity === 'all' || checkPermission(permissions[el.entity as keyof Permissions], el.permissions),
          )
          .map((el, index) => {
            const isLinkArray = Array.isArray(el.link);

            return (
              <NavLink
                end
                key={index}
                to={isLinkArray ? el.link[0] : (el.link as string)}
                className={({ isActive }) =>
                  isLinkArray ? linkStyles(el.link.includes(pathname)) : linkStyles(isActive)
                }
              >
                <div className={styles.LinkContent}>
                  <div className={styles.LinkIcon}>{el.icon}</div>
                  <span className={styles.LinkTitle}>{t(el.title)}</span>
                </div>
              </NavLink>
            );
          })}
      </nav>

      <button className={styles.MenuToggle} onClick={() => toggle(!isHidden)}>
        <Arrows />
      </button>

      <button className={styles.SignOutButton} onClick={signOutHandler}>
        <LogOut />
        <span>{t('general.sign_out')}</span>
      </button>
    </aside>
  );
};

export default Menu;
