import { useEffect, useLayoutEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Loader } from 'rsuite';

import PageLayout from 'components/PageLayout/PageLayout';

import { useAppDispatch, useAppSelector } from 'hooks/redux';

import { useGetIncompleteAssessmentsQuery } from 'store/api/assessmentApi/assessmentApi';
import { groupsApi } from 'store/api/groupsApi/groupsApi';
import { profileApi } from 'store/api/profileApi/profileApi';
import { toggleIsMobileMenuOpen } from 'store/features/navigationSlice';

import ROUTES from 'router/routes';

import { MOBILE_BREAKPOINT_VALUE } from 'constants/';

function Layout() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useAppSelector((state) => state.auth.user);
  const shouldBeRedirected = useAppSelector((state) => state.auth.shouldBeRedirected);
  const isRedirectLogin = useAppSelector((state) => state.auth.isRedirectLogin);
  const isIndividualUser = useAppSelector((state) => state.permissions.isIndividualUser);
  const selectedGroupId = useAppSelector((state) => state.navigation.selectedGroupId);
  const selectedOrganizationId = useAppSelector((state) => state.navigation.selectedOrganizationId);
  const isLoadingPermissions = useAppSelector((state) => state.permissions.isLoading);
  const isLoadingHeaderNavigation = useAppSelector((state) => state.navigation.isLoading);
  const dashboard = useAppSelector((state) => state.navigation.dashboard);
  const isMobileMenuOpen = useAppSelector((state) => state.navigation.isMobileMenuOpen);
  const isUserFlowRedirect = selectedGroupId && selectedOrganizationId && isIndividualUser && shouldBeRedirected;
  const { data: incompleteAssessments, isLoading: isLoadingIncompleteAssessments } = useGetIncompleteAssessmentsQuery(
    undefined,
    {
      skip: !isUserFlowRedirect,
    },
  );
  const token = localStorage.getItem('access');

  useEffect(() => {
    if (location.pathname === '/') {
      navigate(dashboard);
    }
  }, [dispatch, navigate, dashboard, location, isMobileMenuOpen]);

  useEffect(() => {
    if (isMobileMenuOpen) {
      dispatch(toggleIsMobileMenuOpen());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useLayoutEffect(() => {
    if (token) {
      dispatch(groupsApi.endpoints.getGroupsHierarchyNavigation.initiate());
      dispatch(profileApi.endpoints.getMyProfile.initiate());
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (incompleteAssessments && isUserFlowRedirect) {
      if (incompleteAssessments.length === 0) {
        navigate('/');
        return;
      }

      if (incompleteAssessments.length === 1) {
        navigate(
          ROUTES.DIAGNOSTIC_TAKE(
            incompleteAssessments[0].assessment_id,
            incompleteAssessments[0].windowed_assignment_version_id,
          ),
        );
        return;
      }

      navigate(ROUTES.DIAGNOSTIC_LIST);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incompleteAssessments]);

  useEffect(() => {
    if (isRedirectLogin) {
      navigate(ROUTES.LOGIN);
    }
  }, [navigate, isRedirectLogin]);

  useEffect(() => {
    const handleWindowResize = () => {
      if (isMobileMenuOpen && window.innerWidth >= MOBILE_BREAKPOINT_VALUE) {
        dispatch(toggleIsMobileMenuOpen());
      }
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [dispatch, isMobileMenuOpen]);

  return token && !user && isLoadingPermissions ? (
    <Loader backdrop size="lg" />
  ) : (
    <PageLayout>
      {isLoadingPermissions ||
      (isLoadingHeaderNavigation && token) ||
      (isUserFlowRedirect && isLoadingIncompleteAssessments) ? (
        <Loader backdrop size="lg" />
      ) : (
        <Outlet />
      )}
    </PageLayout>
  );
}

export default Layout;
