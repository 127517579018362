import { ForwardedRef, MouseEventHandler, forwardRef, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Link } from 'react-router-dom';

import classNames from 'classnames';
import { Breadcrumb as BreadcrumbSuite } from 'rsuite';

import styles from './Breadcrumb.module.scss';

type Props = {
  items: Array<{ name: string; href?: string; active?: boolean; onClick?: MouseEventHandler<HTMLAnchorElement> }>;
  breadcrumbOutletId?: string;
};

const NavLink = forwardRef(
  (
    props: {
      href: string;
      className: string;
      children: string;
      onClick?: MouseEventHandler<HTMLAnchorElement>;
    },
    ref: ForwardedRef<HTMLAnchorElement | null>,
  ) => (
    <Link ref={ref} to={props.href} className={props.className} onClick={props.onClick}>
      {props.children}
    </Link>
  ),
);

const Breadcrumb = ({ breadcrumbOutletId = 'default-breadcrumb', items }: Props) => {
  const [breadcrumbOutlet, setBreadcrumbOutlet] = useState<HTMLDivElement>();

  useEffect(() => {
    const outletElement = document.getElementById(breadcrumbOutletId) as HTMLDivElement;
    if (outletElement) {
      setBreadcrumbOutlet(outletElement);
    }
  }, [breadcrumbOutletId]);

  if (!breadcrumbOutlet) {
    return null;
  }

  return createPortal(
    <BreadcrumbSuite separator=" | " className={styles.Breadcrumb}>
      {items.map((el) => (
        <BreadcrumbSuite.Item
          key={el.name}
          href={el.href}
          active={el.active}
          as={NavLink}
          className={classNames(styles.Breadcrumb_Item, {
            [styles.Active]: el.active,
          })}
          onClick={el.onClick}
        >
          {el.name}
        </BreadcrumbSuite.Item>
      ))}
    </BreadcrumbSuite>,
    breadcrumbOutlet,
  );
};

export default Breadcrumb;
