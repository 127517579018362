import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Tooltip } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import truncate from 'lodash/truncate';

import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import DataTable from 'components/DataTable/DataTable';
import Loader from 'components/Loader/Loader';
import CloneDiagnosticModal from 'components/Modals/CloneDiagnosticModal/CloneDiagnosticModal';
import CreateDiagnosticAssignmentModal from 'components/Modals/CreateDiagnosticAssignmentModal/CreateDiagnosticAssignmentModal';
import DemoModal from 'components/Modals/DemoModal/DemoModal';
import ModalWrapper from 'components/Modals/ModalWrapper';
import NewSearchBar from 'components/NewSearchBar/NewSearchBar';
import NewSortButton from 'components/NewSortButton/NewSortButton';
import PageTitle from 'components/PageTitle/PageTitle';
import Tags from 'components/Tags/Tags';

import AssignedToCell from '../AssessmentList/components/Table/components/AssignedToCell/AssignedToCell';

import { useAppSelector } from 'hooks/redux';
import { useDebounce } from 'hooks/useDebounce';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import useTableControl from 'hooks/useTableControl';

import {
  useDeleteAssessmentMutation,
  useGetAssessmentByIdQuery,
  useGetDiagnosticAssignmentsQuery,
} from 'store/api/assessmentApi/assessmentApi';

import ROUTES from 'router/routes';

import { checkPermission } from 'helpers/checkUserPermission';
import { formatDate } from 'helpers/date';
import { formatDiagnosticAssignmentCompletionRate } from 'helpers/diagnostic';

import { MOBILE_BREAKPOINT } from 'constants/';

import { TagType } from 'types/assessmentTypes';
import { ButtonFill, ButtonSize } from 'types/enums';
import { PermissionsAction } from 'types/permissionsTypes';

import { ReactComponent as ArrowNarrowRight } from 'assets/images/arrow-narrow-right.svg';
import { ReactComponent as Delete } from 'assets/images/delete-icon.svg';
import { ReactComponent as Edit } from 'assets/images/edit-icon.svg';
import { ReactComponent as NoteList } from 'assets/images/note-list.svg';
import { ReactComponent as PlayIcon } from 'assets/images/play.svg';

import styles from './AssessmentDetails.module.scss';

type DataTableType = {
  id: string;
  assessmentId: string;
  fieldId: number;
  name: string;
  description: string;
  creator: string;
  assignedTo: string[];
  attemptDate: Date;
  isAssignedToCurrentUser: boolean;
};

const AssessmentDetails = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id = '' } = useParams();
  const [openCloneDiagnosticModal, setOpenCloneDiagnosticModal] = useState(false);
  const { page, size, sort, search, onPageChange, onSearching, onSorting } = useTableControl('version_name');
  const [openAssignDiagnosticVersionModal, toggleAssignDiagnosticVersionModal] = useState(false);
  const debouncedSearch = useDebounce(search, 500);
  const selectedGroupId = useAppSelector((state) => state.navigation.selectedGroupId);
  const { assessment, other_users_dashboard_comparison } = useAppSelector((state) => state.permissions.permissions);
  const { data, isLoading, isError } = useGetAssessmentByIdQuery({ id, groupId: selectedGroupId });
  const {
    data: { items: diagnosticAssignments = [], total: diagnosticAssignmentsTotal } = {},
    isLoading: isLoadingDiagnosticAssignments,
    isFetching: isFetchingDiagnosticAssignments,
  } = useGetDiagnosticAssignmentsQuery({ id, page, size, search: debouncedSearch, orderBy: sort.sortBy });
  const canOpenDiagnosticAssignmentDetails = checkPermission(other_users_dashboard_comparison, [
    PermissionsAction.READ,
  ]);
  const { title = '', description = '', creator_full_name = t('general.not_specified') } = data || {};
  const [openDeleteModal, toggleDeleteModal] = useState(false);

  const [openDemoModal, toggleDemoModal] = useState(false);
  const isMobile = useMediaQuery({
    query: MOBILE_BREAKPOINT,
  });

  const handleCloneDiagnosticModalAction = () => {
    if (id) {
      setOpenCloneDiagnosticModal(false);
      navigate(ROUTES.DIAGNOSTIC_CREATE, { state: { cloneAssessmentId: id } });
    }
  };

  const handleCloneDiagnosticModalClose = () => {
    setOpenCloneDiagnosticModal(false);
  };

  const handleCloseDeleteModal = () => toggleDeleteModal(false);

  const handleCloseDemoModal = () => toggleDemoModal(false);

  const [deleteAssessment] = useDeleteAssessmentMutation();

  const handleDelete = () => {
    if (id) {
      deleteAssessment(id)
        .unwrap()
        .then(() => {
          handleCloseDeleteModal();
          navigate(ROUTES.DIAGNOSTIC_LIST);
        });
    }
  };

  const unitTags = data?.units.map(({ id, title }) => ({ id, title, type: TagType.Organization }));

  const handleTryDemoClick = () => {
    toggleDemoModal(true);
  };

  const handleOpenDemo = () => {
    navigate(`${ROUTES.DIAGNOSTIC_TAKE(id, id)}?demo=true`);
  };

  useDocumentTitle([t('page_titles.diagnostic_details')]);

  const getPageTitleAction = () => {
    const actions = [];
    const isEditDisabled =
      isFetchingDiagnosticAssignments ||
      typeof diagnosticAssignmentsTotal !== 'number' ||
      +diagnosticAssignmentsTotal > 0;

    if (checkPermission(assessment, [PermissionsAction.DELETE])) {
      actions.push(
        <ButtonStyled
          key="delete"
          fill={ButtonFill.TransparentRed}
          size={ButtonSize.Link}
          onClick={() => toggleDeleteModal(true)}
          icon={<Delete />}
          justifyStart={isMobile}
        >
          {t('general.delete')}
        </ButtonStyled>,
      );

      if (!isEditDisabled && !isMobile && checkPermission(assessment, [PermissionsAction.UPDATE])) {
        actions.push(
          <ButtonStyled
            key="edit"
            onClick={() => {
              navigate(ROUTES.DIAGNOSTIC_EDIT(id));
            }}
            fill={ButtonFill.Transparent}
            size={ButtonSize.Link}
            icon={<Edit />}
            disabled={
              isFetchingDiagnosticAssignments ||
              typeof diagnosticAssignmentsTotal !== 'number' ||
              +diagnosticAssignmentsTotal > 0
            }
          >
            {t('general.edit')}
          </ButtonStyled>,
        );
      }

      if (isEditDisabled && !isMobile && checkPermission(assessment, [PermissionsAction.CREATE])) {
        actions.push(
          <ButtonStyled
            onClick={() => setOpenCloneDiagnosticModal(true)}
            size={ButtonSize.Small}
            fill={ButtonFill.Outlined}
            clickType="button"
          >
            {t('general.clone')}
          </ButtonStyled>,
        );
      }

      if (!isMobile && checkPermission(assessment, [PermissionsAction.ASSIGN])) {
        actions.push(
          <ButtonStyled
            key="new_assignment"
            size={ButtonSize.Small}
            fill={ButtonFill.Contained}
            onClick={() => {
              toggleAssignDiagnosticVersionModal(true);
            }}
          >
            {t('assessment.new_assignment')}
          </ButtonStyled>,
        );
      }
    }

    return actions;
  };

  const pageTitleActions = getPageTitleAction();

  const getActions = useCallback(
    ({
      id,
      assessmentId,
      isAssignedToCurrentUser,
    }: {
      id: string;
      assessmentId: string;
      isAssignedToCurrentUser: boolean;
    }) => {
      const actions: ReactNode[] = [];

      if (
        isMobile &&
        (checkPermission(assessment, [PermissionsAction.READ]) ||
          checkPermission(assessment, [PermissionsAction.CREATE]))
      ) {
        actions.push(
          <ButtonStyled
            key="open"
            fill={ButtonFill.Transparent}
            size={ButtonSize.Link}
            onClick={() => navigate(ROUTES.DIAGNOSTIC_DETAILS(assessmentId))}
          >
            {t('general.open')}
          </ButtonStyled>,
        );
      }

      if (checkPermission(assessment, [PermissionsAction.READ]) && isAssignedToCurrentUser) {
        actions.push(
          <ButtonStyled
            key="take"
            onClick={(event) => {
              event.stopPropagation();
              navigate(ROUTES.DIAGNOSTIC_TAKE(assessmentId, id));
            }}
            fill={ButtonFill.Contained}
            size={ButtonSize.Small}
          >
            {t('general.take_diagnostic')}
          </ButtonStyled>,
        );
      } else {
        actions.push(
          <ButtonStyled
            onClick={(e) => {
              e.stopPropagation();
              navigate(ROUTES.DIAGNOSTIC_ASSIGNMENT_DETAILS(id));
            }}
            fill={ButtonFill.Outlined}
            size={ButtonSize.Icon}
            icon={<ArrowNarrowRight />}
          />,
        );
      }

      return actions;
    },
    [assessment, isMobile, navigate, t],
  );

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'name',
        headerName: t('assessment.diagnostic_versions_table.internal_name'),
        sortable: false,
        flex: 1,
        renderCell: (params) => (
          <Tooltip title={params.value} placement="bottom-start">
            <span
              style={{
                textDecoration: 'underline',
                color: 'inherit',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {params.value}
            </span>
          </Tooltip>
        ),
      },
      {
        field: 'creator',
        headerName: t('assessment.diagnostic_versions_table.creator'),
        sortable: false,
        valueFormatter: (data) => data.value || ' - ',
        flex: 1,
      },
      {
        field: 'completionRate',
        headerName: t('assessment.diagnostic_versions_table.completion_rate'),
        sortable: false,
        valueFormatter: (data) => formatDiagnosticAssignmentCompletionRate(data.value) || ' - ',
        width: 110,
      },
      {
        field: 'createdDate',
        headerName: t('assessment.diagnostic_versions_table.created_date'),
        sortable: false,
        width: 91,
        valueFormatter: (data) => formatDate(data.value) || ' - ',
      },
      {
        field: 'assignedTo',
        headerName: t('assessment.table.assigned_to'),
        sortable: false,
        valueFormatter: (data) => data.value?.[0] || '-',
        renderCell: (params) => <AssignedToCell value={params.value} formattedValue={params.formattedValue} />,
        width: 125,
      },
      {
        field: 'actions',
        headerName: '',
        align: 'right',
        width: 175,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any, DataTableType>) =>
          getActions({
            id: params.row.id,
            assessmentId: params.row.assessmentId,
            isAssignedToCurrentUser: params.row.isAssignedToCurrentUser,
          }),
      },
    ],
    [getActions, t],
  );

  useEffect(() => {
    if (isError) {
      navigate(ROUTES.DIAGNOSTIC_LIST);
    }
  }, [isError, navigate]);

  useDocumentTitle([title]);

  return (
    <div className={styles.AssessmentDetailsPage}>
      <CreateDiagnosticAssignmentModal
        open={openAssignDiagnosticVersionModal}
        handleClose={() => toggleAssignDiagnosticVersionModal(false)}
        selectedId={id}
      />

      {isLoading ? (
        <Loader flexCenter size="md" />
      ) : (
        <>
          <ModalWrapper
            open={openDeleteModal}
            title={t('delete_diagnostic.delete_diagnostic_title')}
            subtitle={t('delete_diagnostic.delete_diagnostic_subtitle')}
            close={handleCloseDeleteModal}
            action={handleDelete}
          />

          <CloneDiagnosticModal
            open={openCloneDiagnosticModal}
            close={handleCloneDiagnosticModalClose}
            action={handleCloneDiagnosticModalAction}
          />

          <DemoModal open={openDemoModal} handleClose={handleCloseDemoModal} handleOpenDemo={handleOpenDemo} />

          <Breadcrumb
            items={[
              { name: t('assessment.diagnostic'), href: ROUTES.DIAGNOSTIC_LIST },
              {
                name: truncate(title, { length: 33, omission: '...' }),
                href: ROUTES.DIAGNOSTIC_DETAILS(id),
                active: true,
              },
            ]}
          />

          <PageTitle
            title={
              <div className={styles.PageTitle}>
                <h3>{title}</h3>
                <ButtonStyled
                  size={ButtonSize.Small}
                  fill={ButtonFill.Contained}
                  onClick={handleTryDemoClick}
                  icon={<PlayIcon />}
                >
                  {t('assessment.try_demo')}
                </ButtonStyled>
              </div>
            }
            titleTopColumn={isMobile}
          >
            {pageTitleActions.length ? pageTitleActions : null}
          </PageTitle>

          <div className={styles.IconContainer}>
            <NoteList />
          </div>

          <div className={styles.AssessmentInfoContainer}>
            <div className={styles.AssessmentInfo}>
              <span>{t('assessment.description')}</span>
              <p>{description}</p>
            </div>

            <div className={styles.AssessmentInfo}>
              <span>{t('assessment.table.creator')}</span>
              <p>{creator_full_name || t('general.not_specified')}</p>
            </div>
          </div>

          <div className={styles.TagsContainer}>
            <div className={styles.Header}>
              <h3>{t('general.unit')}:</h3>
            </div>
            {unitTags?.length ? <Tags tags={unitTags} /> : <span>{t('general.not_assigned')}</span>}
          </div>

          <div className={styles.TagsContainer}>
            <div className={styles.Header}>
              <h3>{t('assessment.assignments')}:</h3>
            </div>
            <Box display="flex" justifyContent="space-between" style={{ marginBottom: '16px' }}>
              <Box flex={1}>
                <NewSearchBar small search={search} onSearch={onSearching} />
              </Box>
              <Box display="flex" flex={1} justifyContent="flex-end" alignItems="center" gap="8px">
                <NewSortButton sortType={sort.sortType} onSort={onSorting} />
              </Box>
            </Box>
            <DataTable
              newDesign
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    actions:
                      diagnosticAssignments.length > 0 &&
                      diagnosticAssignments.some(
                        (item) =>
                          getActions({
                            id: item.id,
                            assessmentId: item.assessment_id,
                            isAssignedToCurrentUser: item.is_assigned_to_current_user,
                          }).length > 0,
                      ),
                  },
                },
              }}
              data={{
                items:
                  diagnosticAssignments?.map((item) => {
                    const assignedToUsers = item.users.map((item) => item.first_name + ' ' + item.last_name);
                    const assignedToGroups = item.groups.map((item) => item.name);
                    const assignedTo = [...assignedToUsers, ...assignedToGroups];
                    return {
                      id: item.id,
                      assessmentId: item.assessment_id,
                      name: item.version_name,
                      createdDate: item.created_at,
                      completionRate: item.completion_rate,
                      creator: item.creator_full_name,
                      isAssignedToCurrentUser: item.is_assigned_to_current_user,
                      assignedTo,
                    };
                  }) || [],
                total: diagnosticAssignmentsTotal,
              }}
              columns={columns}
              isLoading={isFetchingDiagnosticAssignments || isLoadingDiagnosticAssignments}
              page={page}
              changePage={onPageChange}
              emptyResultText={t('assessment.table.assignments_empty_result')}
              onRowClick={
                canOpenDiagnosticAssignmentDetails
                  ? ({ row: { id } }) => {
                      navigate(ROUTES.DIAGNOSTIC_ASSIGNMENT_DETAILS(id));
                    }
                  : undefined
              }
            />
          </div>
        </>
      )}
    </div>
  );
};

export default AssessmentDetails;
