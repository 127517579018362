import { CompletionRate } from 'types/assessmentTypes';

import { roundPercentage } from './dashboard';

export const formatDiagnosticAssignmentCompletionRate = ({
  completion_rate,
  total_count,
  completed_count,
}: CompletionRate) => {
  return `${roundPercentage((completion_rate * 100) / 100)}% (${completed_count}/${total_count})`;
};
