import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import { format, startOfDay } from 'date-fns';
import difference from 'lodash/difference';

import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import DatePickerInput from 'components/Inputs/DatePickerInput/DatePickerInput';
import Text from 'components/Inputs/Text/Text';
import Loader from 'components/Loader/Loader';
import PageTitle from 'components/PageTitle/PageTitle';
import Tabs from 'components/Tabs/Tabs';

import AssigneeLists from 'pages/Assessments/AssessmentCreate/components/AssessmentSettingTab/components/AssigneeLists/AssigneeLists';

import { useDocumentTitle } from 'hooks/useDocumentTitle';

import {
  useCreateAssignmentMutation,
  useGetAssessmentByIdQuery,
  useGetDiagnosticAssignmentQuery,
  useUpdateDiagnosticAssignmentMutation,
} from 'store/api/assessmentApi/assessmentApi';

import ROUTES from 'router/routes';

import { createDiagnosticAssignmentResolver } from 'helpers/validations';

import { ButtonFill, ButtonSize } from 'types/enums';

import styles from './CreateDiagnosticAssignment.module.scss';

enum Tab {
  AssignTo,
  UnitSet,
  ResultsMapping,
  QuestionMapping,
}

type Form = {
  assessment_image: string;
  assignment_name: string;
  diagnostic_name: string;
  start_date: Date | null;
  end_date: Date | null;
  automatic_reminders: string;
  automatic_reminders_check: boolean;
  final_reminder: boolean;
  is_default: boolean;
  show_context: boolean;
  include_workday_sso: boolean;
  user_ids: string[];
  group_ids: string[];
  unit_ids: string[];
};

const CreateDiagnosticAssignment = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const isEdit = !!id;
  const {
    diagnosticAssignmentName,
    selectedDiagnostic,
  }: {
    diagnosticAssignmentName: string;
    selectedDiagnostic: {
      id: string;
      title: string;
    };
  } = useLocation().state || {};

  const {
    data: diagnosticAssignment,
    isFetching: isLoadingDiagnosticAssignment,
    isError,
  } = useGetDiagnosticAssignmentQuery(id!, {
    skip: !id,
  });

  const { data: assessmentById, isFetching: isLoadingAssessmentById } = useGetAssessmentByIdQuery(
    { id: diagnosticAssignment?.assessment_id! },
    {
      skip: !id || !diagnosticAssignment,
    },
  );

  const [createAssignment, { isLoading: isLoadingCreateAssignment }] = useCreateAssignmentMutation();
  const [updateDiagnosticAssignment, { isLoading: isLoadingUpdateDiagnosticAssignment }] =
    useUpdateDiagnosticAssignmentMutation();

  const [activeTab, setActiveTab] = useState(Tab.AssignTo);

  const {
    register,
    setValue,
    handleSubmit,
    formState,
    getFieldState,
    getValues,
    setError,
    clearErrors,
    watch,
    reset,
    control,
    resetField,
  } = useForm<Form>({
    mode: 'onChange',
    resolver: yupResolver(createDiagnosticAssignmentResolver(t)),
    defaultValues: {
      assignment_name: diagnosticAssignmentName,
      diagnostic_name: selectedDiagnostic?.title || '',
      start_date: null,
      end_date: null,
      // automatic_reminders: '',
      // automatic_reminders_check: false,
      // final_reminder: false,
      // is_default: false,
      // show_context: false,
      // include_workday_sso: false,
      // unit_ids: [],
      user_ids: [],
      group_ids: [],
    },
  });

  useEffect(() => {
    if (!isEdit && !diagnosticAssignmentName) {
      navigate(ROUTES.DIAGNOSTIC_LIST);
    }
  }, [diagnosticAssignmentName, isEdit, navigate]);

  useEffect(() => {
    if (isEdit && diagnosticAssignment && assessmentById) {
      const { users, groups } = assessmentById?.versions.find((item) => item.id === id) || {};
      reset({
        assignment_name: diagnosticAssignment.version_name,
        start_date: new Date(diagnosticAssignment.start_date),
        end_date: new Date(diagnosticAssignment.end_date),
        diagnostic_name: diagnosticAssignment.assessment_title,
        user_ids: users?.map((item) => item.id) || [],
        group_ids: groups?.map((item) => item.id) || [],
      });
    }
  }, [diagnosticAssignment, assessmentById, id, isEdit, reset]);

  const clearErrorTimeout = (field: keyof Form) => {
    setTimeout(() => {
      clearErrors(field);
    }, 2000);
  };

  const onSubmit = async (data: Form) => {
    if (!data.is_default && !getValues('user_ids')?.length && !getValues('group_ids')?.length) {
      setError('user_ids', {
        type: 'empty assignee',
        message: t('assessment.validation.assignee_required'),
      });
      setActiveTab(Tab.AssignTo);
      clearErrorTimeout('user_ids');
      return;
    }
    const { users } = assessmentById?.versions.find((item) => item.id === id) || {};
    const usersIds = users?.map((item) => item.id) || [];

    const updated = data.user_ids;
    const initial = isEdit ? usersIds : [];

    const updatedAssignees = {
      added: difference(updated, initial),
      removed: difference(initial, updated),
    };

    const startDate = format(new Date(data.start_date!), 'yyyy-MM-dd');
    const endDate = format(new Date(data.end_date!), 'yyyy-MM-dd');
    try {
      if (isEdit) {
        await updateDiagnosticAssignment({
          id,
          version_name: data.assignment_name,
          assessment_id: diagnosticAssignment?.assessment_id!,
          start_date: startDate,
          end_date: endDate,
          added_assignee_ids: updatedAssignees.added,
          removed_assignee_ids: updatedAssignees.removed,
        }).unwrap();
        await navigate(ROUTES.DIAGNOSTIC_ASSIGNMENT_DETAILS(id));
        toast.success<string>(t('assessment.success_assignment_updated'));
      } else {
        await createAssignment({
          version_name: data.assignment_name,
          assessment_id: selectedDiagnostic.id,
          start_date: startDate,
          end_date: endDate,
          assignee_ids: data.user_ids,
        }).unwrap();
        await navigate(ROUTES.DIAGNOSTIC_LIST);
        toast.success<string>(t('assessment.success_assignment_created'));
      }
    } catch (error) {
      toast.error(t('assessment.send_assignment_error'));

      // if (isValidationServerError(error)) {
      //   toast.error(error.data.detail[0].msg);
      //   return;
      // }

      // if (isServerErrorWithTranslation(error) && error.data.detail.error === 'server.already_exists') {
      //   toast.error(
      //     t('assessment.validation.default_already_exists', {
      //       name: error.data.detail.items.title,
      //     }),
      //   );
      //   return;
      // }

      // toast.error(t('general.something_went_wrong'));
    }
  };

  // const statuses = [{ value: '5', label: 'every 5 days' }];

  const getPageTitleAction = () => {
    const actions = [];

    actions.push(
      <ButtonStyled
        key="delete"
        fill={ButtonFill.Outlined}
        size={ButtonSize.Link}
        onClick={() => navigate(isEdit ? ROUTES.DIAGNOSTIC_ASSIGNMENT_DETAILS(id) : ROUTES.DIAGNOSTIC_LIST)}
      >
        {t('general.cancel')}
      </ButtonStyled>,
    );

    actions.push(
      <ButtonStyled
        key="save|edit"
        onClick={handleSubmit(onSubmit)}
        fill={ButtonFill.Contained}
        size={ButtonSize.Link}
        disabled={
          isLoadingDiagnosticAssignment ||
          isLoadingAssessmentById ||
          isLoadingUpdateDiagnosticAssignment ||
          isLoadingCreateAssignment
        }
        loading={isLoadingUpdateDiagnosticAssignment || isLoadingCreateAssignment}
      >
        {isEdit ? t('general.save') : t(t('assessment.send_assignment'))}
      </ButtonStyled>,
    );

    return actions;
  };

  useEffect(() => {
    if (isError && isEdit) {
      navigate(ROUTES.DIAGNOSTIC_LIST);
    }
  }, [isEdit, isError, navigate]);

  const pageTitleActions = getPageTitleAction();

  useDocumentTitle(
    id ? [t('page_titles.diagnostic_assignment_edit')] : [t('page_titles.diagnostic_assignment_create')],
  );

  return (
    <div style={{ width: '100%' }}>
      <Breadcrumb
        items={[
          { name: t('assessment.diagnostic'), href: ROUTES.DIAGNOSTIC_LIST },
          {
            name: isEdit ? t('assessment.edit_assignment') : t('assessment.create_assignment'),
            active: true,
          },
        ]}
      />

      <PageTitle
        title={
          <div className={styles.PageTitle}>
            <h3>{isEdit ? t('assessment.edit_assignment') : t('assessment.create_assignment')}</h3>
          </div>
        }
      >
        {pageTitleActions.length ? pageTitleActions : null}
      </PageTitle>

      {isLoadingDiagnosticAssignment || isLoadingAssessmentById ? (
        <Loader size="lg" center />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} id={'hook-form'} className={styles.SettingsContainer}>
          <div className={styles.SidebarContainer}>
            <div>
              <div className={styles.Section}>
                <Text
                  label={t('assessment.assignment_name')}
                  placeholder={t('assessment.assignment_name')}
                  register={register('assignment_name')}
                  error={getFieldState('assignment_name', formState)}
                  error_message={formState.errors?.assignment_name?.message}
                />
                <Text
                  disabled
                  label={t('assessment.diagnostic_name')}
                  placeholder={t('assessment.diagnostic_name')}
                  register={register('diagnostic_name')}
                  error={getFieldState('diagnostic_name', formState)}
                  error_message={formState.errors?.diagnostic_name?.message}
                />

                <Controller
                  control={control}
                  name="start_date"
                  render={({ field: { onChange, value } }) => (
                    <DatePickerInput
                      label={t('assessment.start_date')}
                      value={value as Date}
                      onChange={onChange}
                      onClear={() => resetField('start_date', { defaultValue: null })}
                      error_message={formState.errors?.start_date?.message}
                      shouldDisableDate={(date) => {
                        const endDate = watch('end_date');
                        const today = startOfDay(new Date());
                        const normalizedDate = startOfDay(date);
                        if (today > normalizedDate) return true;
                        if (!date || !endDate) return false;
                        const normalizedEndDate = startOfDay(new Date(endDate));
                        return normalizedDate > normalizedEndDate;
                      }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="end_date"
                  render={({ field: { onChange, value } }) => (
                    <DatePickerInput
                      label={t('assessment.end_date')}
                      value={value as Date}
                      onChange={onChange}
                      onClear={() => resetField('end_date', { defaultValue: null })}
                      error_message={formState.errors?.end_date?.message}
                      shouldDisableDate={(date) => {
                        const startDate = watch('start_date');
                        const today = startOfDay(new Date());
                        const normalizedDate = startOfDay(date);
                        if (today > normalizedDate) return true;
                        if (!date || !startDate) return false;
                        const normalizedStartDate = startOfDay(new Date(startDate));
                        return normalizedDate < normalizedStartDate;
                      }}
                    />
                  )}
                />
              </div>

              {/* <div className={styles.Section}>
              <Controller
                control={control}
                name="automatic_reminders_check"
                render={({ field: { onChange, value } }) => (
                  <SwitchInput label={t('inputs.automatic_reminders')} onChange={onChange} checked={value} />
                )}
              />

              <Controller
                control={control}
                name="automatic_reminders"
                render={({ field: { onChange, value } }) => (
                  <Select
                    required
                    value={value}
                    onSelect={onChange}
                    data={statuses}
                    isLoading={false}
                    label={t('inputs.automatic_reminders')}
                    placeholder={t('inputs.automatic_reminders')}
                    error={getFieldState('automatic_reminders', formState)}
                    error_message={t(formState?.errors.automatic_reminders?.message || '')}
                  />
                )}
              />

              <Controller
                control={control}
                name="final_reminder"
                render={({ field: { onChange, value } }) => (
                  <SwitchInput
                    description={t('inputs.final_reminder_description')}
                    label={t('inputs.final_reminder')}
                    onChange={onChange}
                    checked={value}
                  />
                )}
              />
            </div>

              <div className={styles.Section}>
              <Box mb={3}>
                <Stack>
                  <Controller
                    control={control}
                    name="is_default"
                    render={({ field: { onChange, value } }) => (
                      <Checkbox checked={value} onChange={() => onChange(!value)}>
                        <span className={classNames(styles.Checkbox, { [styles.Active]: value })}>
                          {t('assessment.default')}
                        </span>
                      </Checkbox>
                    )}
                  />

                  <Controller
                    control={control}
                    name="show_context"
                    render={({ field: { onChange, value } }) => (
                      <Checkbox checked={value} onChange={() => onChange(!value)}>
                        <span className={classNames(styles.Checkbox, { [styles.Active]: value })}>
                          {t('assessment.enable_context')}
                        </span>
                      </Checkbox>
                    )}
                  />

                  <Controller
                    control={control}
                    name="include_workday_sso"
                    render={({ field: { onChange, value } }) => (
                      <Checkbox checked={value} onChange={() => onChange(!value)}>
                        <span className={classNames(styles.Checkbox, { [styles.Active]: value })}>
                          {t('assessment.include_workday_sso')}
                        </span>
                      </Checkbox>
                    )}
                  />
                </Stack>
              </Box>
            </div> */}
            </div>
          </div>

          <div className={styles.MainContainer}>
            <Tabs tabs={[{ title: t('assessment.assign_to') }]} activeTab={activeTab} changeTab={setActiveTab} />

            {activeTab === Tab.AssignTo && (
              <AssigneeLists
                userList={watch('user_ids')}
                groupList={watch('group_ids')}
                setValue={setValue as any}
                error={formState.errors.user_ids?.message as string}
              />
            )}
          </div>
        </form>
      )}
    </div>
  );
};

export default CreateDiagnosticAssignment;
