import { useTranslation } from 'react-i18next';

import NewSearch from 'components/Inputs/NewSearch/NewSearch';

import styles from './NewSearchBar.module.scss';

type Props = {
  search: string;
  onSearch: (data: string) => void;
  small?: boolean;
};

const NewSearchBar = ({ search, onSearch, small }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.SearchInputContainer}>
      <NewSearch small={small} onSearch={onSearch} value={search} placeholder={t('general.search_placeholder')} />
    </div>
  );
};

export default NewSearchBar;
