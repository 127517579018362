import React from 'react';

import { Box } from '@mui/material';
import { DatePicker } from 'rsuite';

import { ReactComponent as CalendarIcon } from 'assets/images/calendar.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/delete-icon.svg';

import InputLabel from '../InputLabel/InputLabel';

import styles from '../Inputs.module.scss';
import stylesDatePickerInput from './DatePickerInput.module.scss';

type Props = {
  label: string;
  value: Date;
  onChange: (value: Date | null) => void;
  error_message?: string;
  onClear?: () => void;
  shouldDisableDate?: (date: Date) => boolean;
};

export default function DatePickerInput({ label, value, shouldDisableDate, onChange, onClear, error_message }: Props) {
  return (
    <div className={styles.CustomInput}>
      <InputLabel errorMessage={error_message} label={label} />
      <Box display="flex" alignItems="center">
        <DatePicker
          oneTap
          format="MMM dd, yyyy"
          cleanable={false}
          value={value}
          onChange={onChange}
          style={{ flex: 1 }}
          className="custom-date-picker"
          caretAs={CalendarIcon}
          shouldDisableDate={shouldDisableDate}
        />
        {value && (
          <button type="button" onClick={onClear} className={stylesDatePickerInput.ClearButton}>
            <DeleteIcon className={stylesDatePickerInput.Icon} />
          </button>
        )}
      </Box>
    </div>
  );
}
