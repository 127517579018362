import { initReactI18next } from 'react-i18next';

import i18n, { t } from 'i18next';
import Backend from 'i18next-http-backend';

import { BoardLanguage } from 'types/createAssessmentTypes';
import { ErrorEntities, IServerErrorWithTranslation } from 'types/errorTypes';

const TRANSLATION_VERSION = new Date().getTime();

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: BoardLanguage.En,
    fallbackLng: BoardLanguage.En,
    returnNull: false,
    backend: {
      loadPath: `/languages/{{lng}}.json?v=${TRANSLATION_VERSION}`,
    },
    interpolation: {
      escapeValue: false,
    },
  });

export const getErrorEntity = (error: IServerErrorWithTranslation) => {
  const { detail } = error.data;
  switch (detail.name) {
    case ErrorEntities.Group: {
      return { entity: t('server.entity.group'), value: detail.items.name };
    }
    case ErrorEntities.User: {
      const value = Object.keys(detail.items).map((key) => {
        if (key === 'name') {
          return (detail.items[key] as string[]).join(', ');
        }
        if (key === 'email') {
          return detail.items[key];
        }
        return key;
      });
      return { entity: t('server.entity.user'), value };
    }
  }
  return {};
};
